<template>
  <div
    class="cursor-pointer border-primary py-3 px-7 text-sm font-medium"
    :class="containerClass"
  >
    <p :class="textClass">{{ title }}</p>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: {
    title: String,
    active: Boolean,
  },
  setup(props) {
    const textClass = computed(() => ({
      "text-primary": props.active,
      "text-blur": !props.active,
      "hover:opacity-60": !props.active,
    }));

    const containerClass = computed(() => ({
      "border-b-2": props.active,
    }));

    return {
      textClass,
      containerClass,
    };
  },
};
</script>
