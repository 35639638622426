<template>
  <div>
    <div class="flex justify-end">
      <BaseButton
        @click="download"
        data-cy="download-button"
        type="outline"
        title="ดาวน์โหลด"
      >
        <IconDownload />
      </BaseButton>
    </div>

    <class-schedule-filter-bar
      v-model="header"
      :items="{
        degree: false,
        academicYear: false,
        semester: true,
        teacher: false,
        room: false,
        studentYear: false,
        showTable: false,
      }"
      @update:modelValue="onFilterHeaderChange"
    />

    <div
      v-if="schedules.length > 0 && header.showTable"
      class="section-container bg-gray-lighter p-2"
      style="box-shadow: 0px 0px 12px rgba(80, 86, 131, 0.2)"
    >
      <div v-for="weekday in schedules">
        <ScheduleTableSemester
          :data="weekday.studentYears"
          :group-title="weekday.title"
          :color-primary="weekday.colorPrimary"
          :color-secondary="weekday.colorSecondary"
          :row-title-render="(title) => `ปี ${title}`"
          :week-day-key="weekday.key"
          :semester="header.semester"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectorButton from "@/components/SelectorButton";
import SectionTable from "@/components/SectionTable";
import ScheduleTableSemester from "@/components/ScheduleTableSemester";
import ClassScheduleFilterBar from "@/components/ClassScheduleFilterBar";
import BaseButton from "@/components/BaseButton";
import IconDownload from "@/assets/icon/download.svg";

import { useDefaultHeader } from "@/composables/filter-bar.js";
import { useDataService } from "@/composables/data-service.js";
import { computed, ref, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
import { useLoading } from "vue-loading-overlay";

import axios from "axios";
import EnvProvider from "jvjr-docker-env";

export default {
  components: {
    SelectorButton,
    SectionTable,
    ScheduleTableSemester,
    ClassScheduleFilterBar,
    BaseButton,
    IconDownload,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const $loading = useLoading();

    let header = ref({
      degree: null,
      academicYear: null,
      semester: null,
      teacher: null,
      room: null,
      studentYear: 1,
      showTable: true,
    });

    let { semesters } = useDataService();

    let schedules = ref([]);

    const onFilterHeaderChange = () => {
      if (header.value.semester) {
        updateRouteQueryString();

        fetchSchedules();
      }
    };

    const updateRouteQueryString = () => {
      router.push({
        name: "classScheduleSemester",
        query: {
          semesterId: header.value.semester?.id,
          academicYear: header.value.academicYear,
        },
      });
    };

    const fetchSchedules = async () => {
      schedules.value = [];
      const loader = $loading.show({});

      try {
        const { semester } = header.value;

        const response = await axios.get(
          `class-schedule/semester/${semester?.id}`
        );

        schedules.value = response.data.data;
        loader.hide();
      } catch (error) {
        loader.hide();
        console.log(error);
      }
    };

    const download = async () => {
      const loader = $loading.show({});

      try {
        const { semester } = header.value;

        loader.hide();
        window.open(
          `${EnvProvider.value("BASE_API_URL")}class-schedule/semester/${
            semester?.id
          }/excel`
        );
      } catch (error) {
        loader.hide();
        console.log(error);
      }
    };

    watch([semesters], () => {
      if (semesters.value.length > 0) {
        const { semester, academicYear } = useDefaultHeader(
          { semesters },
          route,
          router
        ).value;

        header.value = {
          ...header.value,
          semester: semester,
          academicYear: academicYear,
        };

        updateRouteQueryString();
      }
    });

    return {
      schedules,

      header,

      onFilterHeaderChange,
      download,
    };
  },
};
</script>
