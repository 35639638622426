<template>
  <div>
    <div class="flex justify-end">
      <BaseButton
        @click="download"
        data-cy="download-button"
        type="outline"
        title="ดาวน์โหลด"
      >
        <IconDownload />
      </BaseButton>
    </div>

    <class-schedule-filter-bar
      v-model="header"
      :items="{
        degree: false,
        academicYear: false,
        semester: true,
        teacher: false,
        room: true,
        studentYear: false,
        showTable: false,
      }"
      @update:modelValue="onFilterHeaderChange"
    />

    <div
      v-if="schedules.length > 0"
      class="section-container bg-gray-lighter p-2"
      style="box-shadow: 0px 0px 12px rgba(80, 86, 131, 0.2)"
    >
      <div class="py-6">
        <p class="text-center font-light">ตารางการใช้{{ header.room.name }}</p>
        <p class="text-center text-sm font-medium">
          {{ header.semester.name }}/{{ header.semester.year }}
        </p>
      </div>

      <ScheduleTable v-show="header.showTable" class="mb-4" :data="schedules">
        <template
          #itemSchedule="{
            titleRow,
            titleGroup,
            timeSlot,
            canAccessCourseMatching,
            slot,
          }"
        >
          <ScheduleItemDegree
            :data-cy="`schedule-item-start-${slot.start}-end-${slot.end}-weekday-${titleRow}-roomId-${header.room.id}-`"
            :weekDay="titleGroup"
            :studentYear="titleRow"
            :semesterId="header.semester.id"
            :startTime="timeSlot"
            :canAccessCourseMatching="canAccessCourseMatching"
            :slot="slot"
          />
        </template>
      </ScheduleTable>
    </div>
  </div>
</template>

<script>
import SelectorButton from "@/components/SelectorButton";
import SectionTable from "@/components/SectionTable";
import ScheduleTable from "@/components/ScheduleTable";
import ClassScheduleFilterBar from "@/components/ClassScheduleFilterBar";
import BaseButton from "@/components/BaseButton";
import IconDownload from "@/assets/icon/download.svg";
import ScheduleItemDegree from "@/components/ScheduleItemDegree";

import { useDataService } from "@/composables/data-service.js";
import { useDefaultHeader } from "@/composables/filter-bar.js";
import { useRouter, useRoute } from "vue-router";

import { computed, ref, watch } from "vue";

import axios from "axios";
import EnvProvider from "jvjr-docker-env";

export default {
  components: {
    SelectorButton,
    SectionTable,
    ScheduleTable,
    ClassScheduleFilterBar,
    ScheduleItemDegree,
    BaseButton,
    IconDownload,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();

    let header = ref({
      degree: null,
      academicYear: null,
      semester: null,
      teacher: null,
      room: null,
      studentYear: 1,
      showTable: true,
    });

    let { semesters, rooms } = useDataService();

    let schedules = ref([]);
    let sections = ref();

    const onFilterHeaderChange = () => {
      if (header.value.room && header.value.semester) {
        updateRouteQueryString();

        fetchSchedules();
        fetchSections();
      }
    };

    const updateRouteQueryString = () => {
      const { room, semester, academicYear } = header.value;

      router.replace({
        name: "roomSchedule",
        query: {
          semesterId: semester?.id,
          academicYear: academicYear,
          roomId: room?.id,
        },
      });
    };

    const fetchSchedules = async () => {
      schedules.value = [];

      try {
        const { room, semester } = header.value;

        const response = await axios.get(
          `room-schedule/room/${room.id}/semester/${semester.id}`
        );

        schedules.value = response.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const fetchSections = async () => {
      try {
        const { room, semester } = header.value;

        const response = await axios.get(
          `section?roomId=${room.id}&semesterId=${semester.id}`
        );

        sections.value = response.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const download = async () => {
      try {
        const { room, semester } = header.value;

        window.open(
          `${EnvProvider.value("BASE_API_URL")}room-schedule/room/${
            room.id
          }/semester/${semester.id}/excel`
        );
      } catch (error) {
        console.log(error);
      }
    };

    watch([semesters, rooms], () => {
      if (semesters.value.length > 0 && rooms.value.length) {
        const { semester, academicYear, room } = useDefaultHeader(
          { semesters, rooms },
          route,
          router
        ).value;

        header.value = {
          ...header.value,
          semester: semester,
          academicYear: academicYear,
          room: room,
        };

        updateRouteQueryString();
      }
    });

    return {
      schedules,
      sections,

      header,

      onFilterHeaderChange,
      download,
    };
  },
};
</script>
