<template>
  <div class="relative flex">
    <Sidebar @change="onSideBarModeChange"></Sidebar>

    <div
      class="z-10 w-full bg-white"
      style="transition: 0.5s; height: calc(100vh - 20px)"
    >
      <top-header :title="headerTitle"></top-header>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ArchLogo from "@/assets/logo.svg";
import IconDb from "@/assets/icon/db.svg";
import IconChart from "@/assets/icon/chart.svg";
import IconForm from "@/assets/icon/form.svg";
import IconCalendar from "@/assets/icon/calendar.svg";
import IconBuilding from "@/assets/icon/building.svg";

import IconLogout from "@/assets/icon/logout.svg";

import SideMenuItem from "@/components/SideMenuItem.vue";
import TopMenuItem from "@/components/TopMenuItem.vue";
import TopSubMenuItem from "@/components/TopSubMenuItem.vue";

import Sidebar from "@/components/Sidebar.vue";

import TopHeader from "@/components/TopHeader.vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { useStore } from "vuex";
import { ref, on, watch } from "vue";

import axios from "axios";
import { computed } from "@vue/runtime-core";
import { useCan } from "@/composables/authentication";
import EnvProvider from "jvjr-docker-env";

export default {
  components: {
    ArchLogo,

    IconDb,
    IconChart,
    IconForm,
    IconCalendar,
    IconLogout,
    IconBuilding,

    SideMenuItem,
    TopMenuItem,
    TopSubMenuItem,
    TopHeader,
    Sidebar,

    useRoute,
  },
  setup() {
    const sideBarMode = ref("expanded");

    const onSideBarModeChange = (mode) => {
      sideBarMode.value = mode;
    };

    const route = useRoute();
    const router = useRouter();

    const headerTitleFromRoute = {
      masterData: "ฐานข้อมูล",
      transactionalData: "ฟอร์มนำเข้าข้อมูล",
      classSchedule: "ตารางสอน",
      roomSchedule: "ตารางการใช้ห้อง",
    };

    const headerTitle = ref("");

    watch(
      route,
      (to) => {
        headerTitle.value = headerTitleFromRoute[to.meta?.topRoute];
      },
      { flush: "pre", immediate: true, deep: true }
    );

    return {
      sideBarMode,
      onSideBarModeChange,

      headerTitle,
    };
  },
};
</script>
