<template>
  <div class="schedule-table">
    <table>
      <thead>
        <tr>
          <th
            class="w-20"
            :style="{
              backgroundColor: colorPrimary,
              borderColor: colorPrimary,
            }"
            colspan="2"
          >
            {{ groupTitle }}
          </th>
          <th v-for="slot in timeSlots" class="time-slot">
            {{ slot.format("HH:mm") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(studentYear, studentYearIndex) in data">
          <template v-for="(degree, degreeIndex) in studentYear.degrees">
            <tr v-for="(rows, rowsIndex) in degree.rows">
              <th
                v-if="degreeIndex == 0 && rowsIndex == 0"
                class="text-center text-primary"
                :rowspan="studentYear.numberOfRows"
                :style="{
                  backgroundColor: colorSecondary,
                  borderLeftColor: colorPrimary,
                  borderRightColor: colorPrimary,
                  borderBottomColor: colorPrimary,
                }"
              >
                ปี {{ studentYear.title }}
              </th>

              <th
                v-if="rowsIndex == 0"
                class="text-center text-primary"
                :rowspan="degree.numberOfRows"
                :style="{
                  backgroundColor: colorSecondary,
                  borderLeftColor: colorPrimary,
                  borderRightColor: colorPrimary,
                }"
              >
                {{ degree.title }}
              </th>

              <td
                v-for="(slot, slotIndex) in rows"
                :colspan="slot.numberOfSlots"
              >
                <template v-if="!slot.isFree">
                  <ScheduleItemDegree
                    :data-cy="`schedule-item-start-${slot.start}-end-${slot.end}-weekday-${weekDayKey}-degreeId-${degree.key}-studentYear-${studentYear.key}`"
                    :weekDay="weekDayKey"
                    :studentYear="studentYear.key"
                    :degreeId="degree.key"
                    :semesterId="semester.id"
                    :startTime="slot.timeSlot"
                    :canAccessCourseMatching="canAccessCourseMatching"
                    :slot="slot"
                  />
                </template>

                <ScheduleItemFree
                  v-else
                  :weekDay="weekDayKey"
                  :studentYear="studentYear.key"
                  :degreeId="degree.key"
                  :semesterId="semester.id"
                  :startTime="slot.timeSlot"
                  :canAccessCourseMatching="canAccessCourseMatchingCreate"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { useScheduleTable } from "@/composables/schedule-table";
import { useRouter, useRoute } from "vue-router";
import { useCan } from "@/composables/authentication";
import { useStore } from "vuex";

import IconBuilding from "@/assets/icon/building.svg";
import IconAdd from "@/assets/icon/add.svg";

import ScheduleItemDegree from "@/components/ScheduleItemDegree";
import ScheduleItemFree from "@/components/ScheduleItemFree";

export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    groupTitle: {
      type: String,
      default: "",
    },
    colorPrimary: {
      type: String,
    },
    colorSecondary: {
      type: String,
    },
    rowTitleRender: {
      type: Function,
      default: (title) => title,
    },
    weekDayKey: {
      type: String,
    },
    semester: {
      type: Object,
    },
  },
  components: {
    IconBuilding,
    IconAdd,
    ScheduleItemDegree,
    ScheduleItemFree,
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const user = computed(() => store.state.authentication);

    const { canAccessCourseMatching, canAccessCourseMatchingCreate, canAccessCourseMatchingEdit } = useCan();

    const { timeSlots } = useScheduleTable();

    const navigateCourseMatching = (slot) => {
      const { semesterId, degreeId, courseId } = slot;

      router.push({
        name: "courseMatching",
        query: { semesterId, degreeId, courseId },
      });
    };

    const navigateCourseMatchingFree = (studentYear, degreeId, slot) => {
      console.log(studentYear, degreeId, slot);

      router.push({
        name: "courseMatching",
        query: {
          weekDay: props.weekDayKey,
          studentYear: studentYear,
          degreeId: degreeId,
          semesterId: props.semester.id,
          startTime: slot.timeSlot,
        },
      });
    };

    return {
      timeSlots,
      navigateCourseMatching,
      navigateCourseMatchingFree,

      canAccessCourseMatching,
      canAccessCourseMatchingCreate,
      canAccessCourseMatchingEdit,
    };
  },
};
</script>

<style scoped>
@import url("../css/schedule-table.css");
</style>

<style scoped>
th {
  width: 80px;
  padding: 6px 6px;
  @apply h-12;
}

td {
  width: 80px;
  padding: 6px 6px;
  @apply h-36;
}
</style>
