<template>
  <div class="flex">
    <router-link v-for="item in topMenuItems" :to="{ name: item.routeName }">
      <top-menu-item
        :active="$route.meta.masterLayout == item.key"
        :title="item.title"
      />
    </router-link>
  </div>

  <div class="h-1.5 w-full bg-gray"></div>

  <div
    class="h-full w-full py-5"
    style="
      background-color: #f3f2f8;
      max-height: calc(100% - 190px);
      overflow-y: scroll;
    "
  >
    <router-view></router-view>
  </div>
</template>

<script>
import ArchLogo from "@/assets/logo.svg";
import IconDb from "@/assets/icon/db.svg";
import IconChart from "@/assets/icon/chart.svg";
import IconForm from "@/assets/icon/form.svg";
import IconCalendar from "@/assets/icon/calendar.svg";
import IconLogout from "@/assets/icon/logout.svg";

import SideMenuItem from "@/components/SideMenuItem.vue";
import TopMenuItem from "@/components/TopMenuItem.vue";
import TopSubMenuItem from "@/components/TopSubMenuItem.vue";

import TopHeader from "@/components/TopHeader.vue";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    ArchLogo,

    IconDb,
    IconChart,
    IconForm,
    IconCalendar,
    IconLogout,

    SideMenuItem,
    TopMenuItem,
    TopSubMenuItem,
    TopHeader,
  },
  setup() {
    const topMenuItems = computed(() => [
      {
        key: "courseMatching",
        title: "ตารางสอน",
        routeName: "courseMatching",
      },
      {
        key: "examMatching*",
        title: "ตารางสอบ",
        routeName: "courseMatching",
      },
    ]);

    return {
      topMenuItems,
    };
  },
};
</script>

<style scoped>
a:first-child > div {
  @apply ml-10 border-l;
}
</style>
