<template>
  <vue-final-modal
    name="imageUploaderModal"
    v-model="show"
    classes="modal-container"
    content-class="modal-content"
    @closed="$emit('closed')"
  >
    <template v-slot="{ params }">
      <div class="flex h-auto flex-col items-center justify-center">
        <DropZone
          v-if="show"
          @sending="
            (file, xhr, formData) =>
              onImageUploaded(xhr, params.row, params.key)
          "
          :maxFiles="Number(1)"
          :url="fileUploaderUrl"
          paramName="image"
          :maxFileSize="20000000"
          :uploadOnDrop="true"
          :multipleUpload="false"
          :parallelUpload="1"
          dropzoneClassName="min-w-64  text-center flex items-center justify-center mb-4  border-gray border-2 border-dashed"
          dropzoneItemClassName="relative h-auto "
          dropzoneDetailsClassName="hidden"
        />

        <BaseButton @click="show = false" title="บันทึก"></BaseButton>
      </div>
    </template>
  </vue-final-modal>
</template>

<script>
import { ref } from "vue";
import { DropZone } from "dropzone-vue";
import BaseButton from "@/components/BaseButton";
import EnvProvider from "jvjr-docker-env";

export default {
  components: {
    DropZone,
    BaseButton,
  },
  emits: ["uploaded"],
  setup(props, context) {
    const show = ref(false);

    const fileUploaderUrl = `${EnvProvider.value("API_URL")}file/upload`;

    const onImageUploaded = (xhr, row, key) => {
      xhr.onreadystatechange = function () {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          const response = JSON.parse(xhr.response);

          context.emit("uploaded", { ...response.data, row, key });
        }
      };
    };

    return { show, onImageUploaded, fileUploaderUrl };
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  width: 450px;
  display: flex;
  justify-items: center;
  justify-content: center;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>
