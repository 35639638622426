<template>
  <vue-final-modal
    name="insertScheduleModal"
    v-model="show"
    classes="modal-container"
    content-class="modal-content"
    :min-width="500"
  >
    <template v-slot="{ params }">
      <div class="flex w-full flex-col items-center justify-center bg-white">
        <h3>รูปภาพห้อง</h3>
        {{params.slot.start}}
        <BaseButton @click="show = false" title="ปิด"></BaseButton>
      </div>
    </template>
  </vue-final-modal>
</template>

<script>
import { ref } from "vue";
import BaseButton from "@/components/BaseButton";

export default {
  components: {
    BaseButton,
  },
  setup(props, context) {
    let show = ref(false);

    return {
      show,
    };
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>
