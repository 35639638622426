<template class="px-10">
  <pre class="text-xs">
  </pre>
  <div class="px-10">
    <div class="section-container flex bg-gray-lighter"
      style="box-shadow: 0px 0px 12px rgba(80, 86, 131, 0.2)">
      <div class="w-1/6">
        <p class="text-sm text-primary">ภาคการศึกษา</p>
        <v-select @option:selected="onSemesterDegreeCourseChange"
          class="w-full"
          v-model="matchingForm.semester"
          :data-cy="`semester`"
          :options="semesters"
          :get-option-label="(semester) => `${semester.name}/${semester.year}`" />
      </div>

      <div class="ml-4 w-5/6">
        <p class="text-sm text-primary">หลักสูตรหลัก</p>
        <v-select @option:selected="onSemesterDegreeCourseChange"
          v-model="matchingForm.degree"
          class="w-full"
          :data-cy="`degree`"
          :options="degrees"
          label="nameTh" />
      </div>
    </div>


    <div class="section-container bg-gray-lighter"
      style="box-shadow: 0px 0px 12px rgba(80, 86, 131, 0.2)">
      <div class="flex">
        <div class="mr-4 w-1/6">
          <p class="text-sm text-primary">รหัสวิชา</p>
          <v-select @option:selected="onSemesterDegreeCourseChange"
            v-model="matchingForm.course"
            class="w-full"
            :data-cy="`course-code`"
            :options="selectedDegreeCourses"
            label="code" />
        </div>

        <div class="flex w-5/6">
          <div class="mr-4 w-full">
            <p class="text-sm text-primary">ชื่อวิชา</p>
            <v-select @option:selected="onSemesterDegreeCourseChange"
              v-model="matchingForm.course"
              class="w-auto"
              :data-cy="`course-name`"
              :options="selectedDegreeCourses"
              label="nameTh" />
          </div>

          <div class="mr-4"
            style="w-full">
            <p class="text-sm text-primary">ประเภทวิชา</p>
            <input data-cy="course-type"
              type="text"
              class="input-text"
              :value="matchingForm?.course?.courseType?.name"
              disabled />
          </div>

          <div class="mr-4"
            style="w-full">
            <p class="text-sm text-primary">ประเภทการสอน</p>
            <input data-cy="special-type"
              type="text"
              class="input-text"
              :value="matchingForm?.course?.credit?.specialType?.name"
              disabled />
          </div>

          <div class=""
            style="w-full">
            <p class="text-sm text-primary">หน่วยกิต</p>
            <input data-cy="course-credit"
              type="text"
              class="input-text"
              :value="matchingForm?.course?.credit?.name"
              disabled />
          </div>
        </div>
      </div>
      <!-- 
      <pre>
        {{sectionDefault}}
      </pre> -->

      <section v-if="sectionDefault">
        <SectionInputGroup v-if="hasLecLab.hasLec"
          v-model="matchingForm.sections.lec"
          :opposite-type-sections="matchingForm.sections.lab"
          type="LEC"
          :special-type="matchingForm.course?.credit?.specialType.key"
          :rooms="rooms"
          :teachers="teachers"
          :semesters="semesters"
          :degrees="degrees"
          :courses="selectedDegreeCourses"></SectionInputGroup>

        <SectionInputGroup v-if="hasLecLab.hasLab"
          v-model="matchingForm.sections.lab"
          :opposite-type-sections="matchingForm.sections.lec"
          type="LAB"
          :special-type="matchingForm.course?.credit?.specialType.key"
          :rooms="rooms"
          :teachers="teachers"
          :semesters="semesters"
          :degrees="degrees"
          :courses="selectedDegreeCourses"></SectionInputGroup>

        <div class="mt-8 flex justify-end">
          <BaseButton data-cy="selected-delete-cancel"
            type="outline"
            title="ยกเลิก"></BaseButton>

          <BaseButton @click="submit"
            data-cy="save-button"
            type="solid"
            title="บันทึก">
            <IconSave class="text-white" />
          </BaseButton>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import BaseButton from "@/components/BaseButton";
import SectionInputGroup from "@/components/SectionInputGroup";

import IconAdd from "@/assets/icon/add.svg";
import IconSave from "@/assets/icon/save.svg";
import IconDelete from "@/assets/icon/delete.svg";

import { useToast } from "vue-toast-notification";
import { useRouter, useRoute } from "vue-router";

import { computed, ref, onMounted, watch } from "vue";
import axios from "axios";

import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  requiredIf,
  email,
  decimal,
} from "@vuelidate/validators";

import { useDataService } from "@/composables/data-service.js";
import {
  sectionObjectTemplate,
  degreeObjectTemplate,
  sectionDefaultObject,
  toSectionsRequestData,
  sectionDataAllEmpty,
  sectionDataNotEmpty,
  sectionFromCreated,
  getOverlappedAttribute,
} from "@/ultils/course-matching-utils.js";

import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    SectionTitle,
    BaseButton,
    IconAdd,
    IconSave,
    IconDelete,
    SectionInputGroup,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const v$ = useVuelidate();

    let { rooms, teachers, semesters, degrees, courses } = useDataService();
    let sectionDefault = ref();

    onMounted(async () => {
      sectionDefault.value = await sectionDefaultObject("LEC", route);
    });

    let matchingForm = ref({
      semester: null,
      degree: null,
      course: null,
      sections: {
        lec: [
          // sectionDefaultObject("LEC", route)
          { ...sectionDefault.value, type: "LEC" },
        ],
        lab: [
          { ...sectionDefault.value, type: "LAB" },
          // sectionDefaultObject("LAB", route)
        ],
      },
    });

    let selectedDegreeCourses = computed(() => {
      const selectedDegree = matchingForm.value.degree;

      if (!selectedDegree) {
        return [];
      }

      return courses.value.filter(
        (course) => course.degree.id == selectedDegree?.id
      );
    });

    let hasLecLab = computed(() => {
      const specialKey = matchingForm.value.course?.credit?.specialType.key;

      return {
        hasLec: ["LEC_ONLY", "LEC_LAB", "STUDIO_LEC_LAB"].includes(specialKey),
        hasLab: [
          "LAB_ONLY",
          "LEC_LAB",
          "COOP",
          "IS",
          "INTERN",
          "B_THESIS",
          "M_THESIS",
          "PHD_THESIS",
          "STUDIO_LAB",
          "STUDIO_LEC_LAB",
        ].includes(specialKey),
      };
    });

    let matchingFormRequestData = computed(() => {
      const { semester, degree, course, sections } = matchingForm.value;
      const { hasLec, hasLab } = hasLecLab.value;

      return {
        semesterId: semester?.id,
        degreeId: degree?.id,
        courseId: course?.id,
        sections: {
          lec: hasLec
            ? sections.lec
              .filter((sec) => !sectionDataAllEmpty(sec))
              .map(toSectionsRequestData)
            : [],
          lab: hasLab
            ? sections.lab
              .filter((sec) => !sectionDataAllEmpty(sec))
              .map(toSectionsRequestData)
            : [],
        },
      };
    });

    const matchingFormCollisionRequestData = computed(() => {
      const { semester, sections } = matchingForm.value;

      const needCheck = (sec) => {
        const { weekDay, startTime, finishTime, room, rooms, teacher } = sec;

        return (
          weekDay !== null &&
          weekDay?.length > 0 &&
          startTime !== null &&
          finishTime !== null &&
          rooms?.length > 0 &&
          // room !== null &&
          teacher !== null
        );
      };

      const enoughInfoToCheckSections = [...sections.lec, ...sections.lab]
        .map((sec) => ({ ...sec, needCheck: needCheck(sec) }))
        .map((sec) => ({
          id: sec?.id,
          inputId: sec?.inputId,
          type: sec?.type,
          weekDay: sec?.weekDay,
          startTime: sec?.startTime,
          finishTime: sec?.finishTime,
          // roomId: sec?.room?.id,
          rooms: sec?.rooms,
          teacherId: sec?.teacher?.id,
          coTeacherIds: sec?.coTeachers?.map((teacher) => teacher.id),
          teacherAllIds: [
            sec?.teacher?.id,
            ...sec?.coTeachers?.map((teacher) => teacher.id) ?? [],
          ],
          needCheck: sec.needCheck,
        }));

      return {
        semesterId: semester?.id,
        sections: enoughInfoToCheckSections,
      };
    });

    const fillHeaderFormWithQueryString = () => {
      const preSemester = semesters.value.find(
        (semester) => semester.id == route.query.semesterId
      );

      const preDegree = degrees.value.find(
        (degree) => degree.id == route.query.degreeId
      );

      const preCourse = courses.value.find(
        (course) => course.id == route.query.courseId
      );

      matchingForm.value = {
        ...matchingForm.value,
        semester: preSemester,
        degree: preDegree,
        course: preCourse,
      };
    };

    const fetchCourseMatching = async () => {
      const { semester, degree, course } = matchingForm.value;

      if (semester && degree && course) {
        const response = await axios.get(
          `course-matching/semester/${semester?.id}/degree/${degree?.id}/course/${course?.id}`
        );

        const { data } = response.data;

        matchingForm.value = {
          ...matchingForm.value,
          sections: {
            lec: [
              { ...sectionDefault.value, type: "LEC" },
            ],
            lab: [
              { ...sectionDefault.value, type: "LAB" },
            ],
          },
        };

        if (data) {
          matchingForm.value = {
            ...data,
            sections: {
              lec: [
                ...data.sections.lec.map(sectionFromCreated, route),
                { ...sectionDefault.value, type: "LEC" },
              ],
              lab: [
                ...data.sections.lab.map(sectionFromCreated, route),
                { ...sectionDefault.value, type: "LAB" },
              ],
            },
          };
        }
      }
    };

    const checkRoomTeacherCollision = async () => {
      const { semester } = matchingForm.value;
      if (semester) {
        const response = await axios.post(
          `section/collision-check/semester/${semester.id}`,
          matchingFormCollisionRequestData.value
        );

        const { data } = response.data;
        const withOverlappedAttrSections = data.sections;
        const withOverlappedLecSections = withOverlappedAttrSections.lec;
        const withOverlappedLabSections = withOverlappedAttrSections.lab;

        const formLecSections = matchingForm.value.sections.lec;
        const formLabSections = matchingForm.value.sections.lab;

        matchingForm.value = {
          ...matchingForm.value,
          sections: {
            lec: formLecSections.map((section) => ({
              ...section,
              ...({} = getOverlappedAttribute(
                withOverlappedLecSections,
                section
              )),
            })),
            lab: formLabSections.map((section) => ({
              ...section,
              ...({} = getOverlappedAttribute(
                withOverlappedLabSections,
                section
              )),
            })),
          },
        };
      }
    };

    const onSemesterDegreeCourseChange = () => {
      const { semester, degree, course } = matchingForm.value;

      updateRouteQueryString(semester, degree, course);

      fetchCourseMatching();
    };

    const updateRouteQueryString = (semester, degree, course) => {
      router.replace({
        name: "courseMatching",
        query: {
          ...route.query,
          semesterId: semester?.id,
          degreeId: degree?.id,
          courseId: course?.id,
        },
      });
    };

    const submit = async () => {
      try {
        const response = await axios.post(
          "course-matching",
          matchingFormRequestData.value
        );

        //Refresh data
        fetchCourseMatching();

        useToast().success("บันทึกแล้ว");
      } catch (error) {
        useToast().error("บันทึกไม่สำเร็จ ข้อมูลไม่ครบถ้วน");
      }
    };

    watch([rooms, teachers, semesters, degrees, courses], () => {
      const areHeaderOptionsReady =
        semesters.value.length > 0 &&
        degrees.value.length > 0 &&
        courses.value.length > 0;

      if (areHeaderOptionsReady) {
        fillHeaderFormWithQueryString();

        fetchCourseMatching();
      }
    });

    watch(
      () => _.cloneDeep(matchingForm.value),
      _.debounce(async (oldMatching, newMatching) => {
        if (JSON.stringify(oldMatching) !== JSON.stringify(newMatching)) {
          checkRoomTeacherCollision();
        }
      }, 500),
      { deep: true }
    );

    return {
      rooms,
      teachers,
      semesters,
      degrees,
      courses,

      sectionDefault,

      selectedDegreeCourses,

      matchingForm,
      matchingFormRequestData,
      matchingFormCollisionRequestData,

      hasLecLab,

      onSemesterDegreeCourseChange,

      submit,

      v$,
    };
  },
};
</script>
