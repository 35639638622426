<template>
  <div class="schedule-table">
    <table>
      <thead>
        <tr>
          <th class="w-32" style="" rowspan="2">ห้อง</th>

          <th
            v-for="weekDay in weekDays"
            colspan="23"
            style="text-align: center; height: 20px"
            :style="{ backgroundColor: weekDay.colorPrimary }"
            class="sticky-top"
          >
            {{ weekDay.title }}
          </th>
        </tr>

        <tr>
          <template v-for="weekDay in weekDays">
            <th
              v-for="slot in timeSlots"
              class="time-slot"
              style="top: 30px; height: 36px"
            >
              {{ slot.format("HH:mm") }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(room, roomIndex) in data">
          <template v-for="(rows, rowsIndex) in room.rows">
            <tr>
              <th
                v-if="rowsIndex == 0"
                :rowspan="room.numberOfRows"
                class="sticky-left"
              >
                {{ room.title }}
              </th>

              <td
                v-for="(slot, slotIndex) in rows"
                :colspan="slot.numberOfSlots"
              >
                <template v-if="!slot.isFree">
                  <slot
                    name="itemSchedule"
                    :titleRow="room.id"
                    :timeSlot="slot.timeSlot"
                    :titleGroup="slot.weekDaySlot"
                    :canAccessCourseMatching="canAccessCourseMatching"
                    :slot="slot"
                  >
                  </slot>
                </template>

                <template v-else>
                  <slot
                    name="itemFree"
                    :titleRow="room.id"
                    :timeSlot="slot.timeSlot"
                    :slot="slot"
                    :canAccessCourseMatching="canAccessCourseMatching"
                  >
                  </slot>
                </template>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import IconBuilding from "@/assets/icon/building.svg";
import { useScheduleTable } from "@/composables/schedule-table";
import { useCan } from "@/composables/authentication";
import { useRouter, useRoute } from "vue-router";
import ScheduleItemDegree from "@/components/ScheduleItemDegree";

export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    groupTitle: {
      type: String,
      default: "",
    },
    colorPrimary: {
      type: String,
    },
    colorSecondary: {
      type: String,
    },
    rowTitleRender: {
      type: Function,
      default: (title) => title,
    },
  },
  components: {
    IconBuilding,
    ScheduleItemDegree,
  },
  setup(props, context) {
    const { timeSlots } = useScheduleTable();
    const { canAccessCourseMatching } = useCan();

    const router = useRouter();

    const weekDays = [
      {
        title: "Mon",
        colorPrimary: "#FFC555",
        colorSecondary: "#FFF7E1",
      },
      {
        title: "Tue",
        colorPrimary: "#FFA1A1",
        colorSecondary: "#FFEAEA",
      },
      {
        title: "Wed",
        colorPrimary: "#62CD8D",
        colorSecondary: "#DFF5E3",
      },
      {
        title: "Thu",
        colorPrimary: "#FFA37B",
        colorSecondary: "#FFEDE2",
      },
      {
        title: "Fri",
        colorPrimary: "#A1C7FF",
        colorSecondary: "#E2EEFF",
      },
      {
        title: "Sat",
        colorPrimary: "#A585FF",
        colorSecondary: "#E3DAFF",
      },
      {
        title: "Sun",
        colorPrimary: "#FF6363",
        colorSecondary: "#FFDCDA",
      },
    ];

    const navigateCourseMatching = (slot) => {
      const { semesterId, degreeId, courseId } = slot;

      router.push({
        name: "courseMatching",
        query: { semesterId, degreeId, courseId },
      });
    };

    return {
      weekDays,

      timeSlots,
      navigateCourseMatching,

      canAccessCourseMatching,
    };
  },
};
</script>

<style scoped>
@import url("../css/schedule-table.css");
</style>

<style scoped>
th {
  min-width: 80px;
  padding: 6px 6px;
  @apply h-12 text-center font-medium text-gray-darker;
}

th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  @apply bg-backdrop text-primary;
}

thead tr:first-child th:first-child {
  z-index: 4;
}
</style>
