<template>
  <div class="flex w-full items-center justify-end">
    <transition name="fade">
      <button
        v-if="editing"
        :data-cy="`row-${index}-cancel`"
        @click="() => $emit('cancelEdit')"
        class="cursor-pointer text-red-500"
      >
        ย้อนกลับ
      </button>
    </transition>

    <transition name="fade">
      <base-button-icon
        :data-cy="`row-${index}-edit`"
        class="animate-fade hover:bg-gray-lighter"
        v-show="!editing && !addingMode"
        @click="() => $emit('edit')"
      >
        <IconEdit />
      </base-button-icon>
    </transition>

    <base-button-icon
      @click="$emit('duplicate')"
      :data-cy="`row-${index}-duplicate`"
      class="hover:bg-gray-lighter"
    >
      <IconDuplicate class="font-bold text-primary" />
    </base-button-icon>

    <base-button-icon
      @click="$emit('delete')"
      :data-cy="`row-${index}-delete`"
      class="hover:bg-red-50"
    >
      <IconDelete class="text-red-500" />
    </base-button-icon>
  </div>
</template>

<script>
import IconEdit from "@/assets/icon/edit.svg";
import IconDelete from "@/assets/icon/delete.svg";
import IconDuplicate from "@/assets/icon/duplicate.svg";

import BaseButton from "@/components/BaseButton";
import BaseButtonIcon from "@/components/BaseButtonIcon";

export default {
  props: {
    editing: Boolean,
    addingMode: {
      type: Boolean,
      default: false,
    },
    index: Number,
  },
  emits: ["edit", "duplicate", "delete"],
  components: {
    IconEdit,
    IconDuplicate,
    IconDelete,
    BaseButton,
    BaseButtonIcon,
  },
};
</script>

<style scoped>
div > * {
  @apply mr-2;
}
</style>
