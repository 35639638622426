<template>
  <div class="flex">
    <router-link
      v-for="item in topMenuItems"
      :to="{ name: item.routeName, query: { tab: item.defaultTab } }"
    >
      <top-menu-item
        :active="$route.meta.masterLayout == item.key"
        :title="item.title"
      />
    </router-link>
  </div>

  <div class="h-1.5 w-full bg-gray"></div>

  <div class="w-full py-5 " style="background-color: #fcfcfe">
    <router-view></router-view>
  </div>
</template>

<script>
import ArchLogo from "@/assets/logo.svg";
import IconDb from "@/assets/icon/db.svg";
import IconChart from "@/assets/icon/chart.svg";
import IconForm from "@/assets/icon/form.svg";
import IconCalendar from "@/assets/icon/calendar.svg";
import IconLogout from "@/assets/icon/logout.svg";

import SideMenuItem from "@/components/SideMenuItem.vue";
import TopMenuItem from "@/components/TopMenuItem.vue";
import TopSubMenuItem from "@/components/TopSubMenuItem.vue";

import TopHeader from "@/components/TopHeader.vue";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    ArchLogo,

    IconDb,
    IconChart,
    IconForm,
    IconCalendar,
    IconLogout,

    SideMenuItem,
    TopMenuItem,
    TopSubMenuItem,
    TopHeader,
  },
  setup() {
    const topMenuItems = computed(() => [
      {
        key: "subject",
        title: "ข้อมูลวิชา",
        routeName: "subjectMasterData",
        defaultTab: "course",
      },
      {
        key: "room",
        title: "ข้อมูลห้อง",
        routeName: "roomMasterData",
        defaultTab: "room",
      },
      {
        key: "user",
        title: "ข้อมูลผู้ใช้",
        routeName: "userMasterData",
        defaultTab: "name-prefix",
      },
      {
        key: "major",
        title: "สาขาวิชา",
        routeName: "majorMasterData",
        defaultTab: "degree",
      },

      {
        key: "semester",
        title: "ภาคการศึกษา",
        routeName: "semesterMasterData",
        defaultTab: "semester",
      },
    ]);

    return {
      topMenuItems,
    };
  },
};
</script>

<style scoped>
a:first-child > div {
  @apply ml-10 border-l;
}


</style>
