<template>
  <div class="flex h-screen w-full items-center justify-center">
    <p class="mt-32">กำลังเข้าสู่ระบบ</p>
  </div>
</template>

<script>
import ArchLogo from "@/assets/logo.svg";
import BaseButton from "@/components/BaseButton";
import EnvProvider from "jvjr-docker-env";
import { useRouter, useRoute } from "vue-router";
import { onMounted } from "vue";
import { useLoading } from "vue-loading-overlay";

import axios from "axios";

export default {
  components: {
    ArchLogo,
    BaseButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loading = useLoading();

    onMounted(async () => {
      const loader = loading.show({});

      const { code } = route.query;
      const callbackUri = `${EnvProvider.value("BASE_URL")}callback`;

      if (route.query.code) {
        try {
          const response = await axios.post("cmu-oauth/cmu-login", {
            code: code,
            callbackUri: callbackUri,
          });

          const { token } = response.data.data;

          localStorage.setItem("token", token);

          const state = JSON.parse(route.query.state);
          const { redirectFrom, redirectToEmsUrl } = state;

          loader.hide();

          if (redirectFrom) {
            window.location = `${redirectFrom}?token=${token}`;
          } else if (redirectToEmsUrl) {
            const emsBaseUrl = `${EnvProvider.value("EMS_BASE_URL")}`;
            const emsExternalUrl = `${emsBaseUrl}login-external`;

            const response = await axios.post("auth/magic-login-link", {
              redirect_path: redirectToEmsUrl,
              external_url: emsExternalUrl
            });

            const { data: { url, external_url } } = response.data;

            window.location = `${external_url}?url=${url}`;
          } else {
            router.replace({ name: "classSchedule" });
          }
        } catch (error) {
          loader.hide();
          router.replace({ name: "login" });
        }
      }
    });
  },
};
</script>
