import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import axios from "axios";

const degreeObjectTemplate = {
  degree: null,
  studentYears: [],
};

const sectionObjectTemplate = {
  id: null,
  number: null,
  weekDay: null,
  startTime: null,
  finishTime: null,
  capacity: null,
  registeredNumber: 0,
  rooms: [],
  degrees: [],
  teacher: null,
  coTeachers: [],
  type: "LEC",
};

const toSectionsRequestData = (sec) => {
  const {
    number,
    weekDay,
    startTime,
    finishTime,
    capacity,
    registeredNumber,
    type,
  } = sec;

  return {
    number,
    weekDay,
    startTime,
    finishTime,
    capacity,
    registeredNumber,
    type,
    roomIds: sec?.rooms?.map((room) => room.id),
    teacherId: sec?.teacher?.id,
    coTeacherIds: sec?.coTeachers?.map((teacher) => teacher.id),
    degrees: sec?.degrees
      ?.filter(
        (degree) => degree?.degree != null && degree?.studentYears?.length > 0
      ) // Remove empty degrees
      .map((degree) => ({
        degreeId: degree?.degree?.id,
        studentYears: degree.studentYears?.map((year) => year.key),
      })), // Map to [1,2,3,4] format
  };
};

const sectionDataNotEmpty = (sec) => {
  const {
    number,
    weekDay,
    startTime,
    finishTime,
    capacity,
    registeredNumber,
    room,
    teacher,
    coTeachers,
    degrees,
  } = sec;

  return (
    number != null &&
    weekDay != null &&
    weekDay?.length != 0 &&
    startTime != null &&
    finishTime != null &&
    capacity != null &&
    registeredNumber != null &&
    rooms?.length != 0 &&
    teacher != null &&
    coTeachers != null &&
    coTeachers?.length != 0 &&
    degrees.filter((d) => d.degree == null || d.studentYears?.length == 0)
      ?.length == 0
  );
};

const sectionDataAllEmpty = (sec) => {
  const {
    number,
    weekDay,
    startTime,
    finishTime,
    capacity,
    registeredNumber,
    rooms,
    teacher,
    coTeachers,
    degrees,
  } = sec;

  return (
    (number == null || number == "") &&
    (weekDay == null || weekDay.length == 0) &&
    startTime == null &&
    finishTime == null &&
    (capacity == null || capacity == "") &&
    (rooms == null || rooms.length == 0) &&
    teacher == null &&
    (coTeachers == null || coTeachers.length == 0) &&
    degrees.filter((d) => d.degree == null && d.studentYears?.length == 0)
      ?.length > 0
  );
};

const sectionFromCreated = (section) => {
  return {
    ...section,
    inputId: uuidv4(),
    degrees: section.degrees.map((degree) => ({
      degree: degree,
      studentYears: degree.pivot.studentYears.map((year) => ({
        key: year,
        name: `ปี ${year}`,
      })),
    })),
  };
};

const sectionDefaultObject = async (type = "LEC", route) => {
  try {
    let rooms = [];
    let teachers = [];

    const responseRooms = await axios.get(`room`);
    rooms = responseRooms.data.data;

    const responseTeachers = await axios.get(`user`);
    teachers = responseTeachers.data.data;

    return _.cloneDeep({
      ...sectionObjectTemplate,
      inputId: uuidv4(),
      degrees: [{ ...degreeObjectTemplate }],
      weekDay: route.query.weekDay ? [route.query.weekDay] : [],
      startTime: route.query.startTime,
      rooms: [],
      teacher: route.query.teacherId
        ? teachers.find((teacher) => teacher.id == route.query.teacherId)
        : null,
      type: type,
    });
  } catch (error) {
    console.log(error);
  }
};

const getOverlappedAttribute = (withOverlappedAttrSections, section) => {
  const findByInputId = ({ inputId }) => inputId == section.inputId;
  const withAttrSection = withOverlappedAttrSections.find(findByInputId);

  return {
    roomOverlapped: withAttrSection?.roomOverlapped,
    teacherOverlapped: withAttrSection?.teacherOverlapped,
  };
};

export {
  sectionObjectTemplate,
  degreeObjectTemplate,
  sectionDefaultObject,
  toSectionsRequestData,
  sectionDataAllEmpty,
  sectionDataNotEmpty,
  sectionFromCreated,
  getOverlappedAttribute,
};
