import { createStore } from "vuex";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      authentication: null,
    };
  },
  mutations: {
    updateAuthentication(state, authentication) {
      state.authentication = authentication;
    },
  },
  actions: {
    updateAuthentication({ commit, state }, authentication) {
      this.commit("updateAuthentication", authentication);
    },
  },
});

export default store;
