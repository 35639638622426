<template>
    <vue-final-modal name="colorModal"
        v-model="show"
        @close="onClose"
        @click-outside="onClose"
        classes="modal-container"
        content-class="modal-content"
        :min-width="200">
        <div class="w-90">
            <p class="text-center mb-4">เลือกสี</p>

            <div class="flex flex-col gap-2">
                <div v-for="colorOption in colorOptions"
                    @click="() => submit(colorOption)"
                    class="justify-between flex hover:bg-gray cursor-pointer">
                    <div class="flex items-center gap-4">
                        <div class="w-6 h-6 rounded-sm"
                            :style="{ borderWidth: '1px', borderColor: colorOption.border, backgroundColor: colorOption.background }">
                        </div>
                        <p>{{ colorOption.name }}</p>
                    </div>
                </div>

                <button @click="submitClearColor"
                    class="border border-primary rounded-md p-1 mt-2">ใช้สีเริ่มต้น</button>

                <div class="my-2"></div>

                <p class="text-center mb-4">หมายเหตุ</p>
                <textarea :value="modelValue"
                    @input="$emit('update:modelValue', $event.target.value)"
                    class="border border-primary"
                    name=""
                    id=""
                    cols="30"
                    rows="5"></textarea>
                <button @click="submitNote"
                    class="border border-primary rounded-md p-1 mt-2">บันทึก</button>
            </div>

        </div>
    </vue-final-modal>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import { ref } from "vue";

export default {
    components: {
        BaseButton,
    },
    emits: ["submit", "submit-note", "close", 'update:modelValue'],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: String,
            default: '',
            required: true
        }
    },
    setup(props, context) {
        const colorOptions = [
            {
                key: 'Yellow',
                border: '#EFCA83',
                background: '#FCEFDC',
                name: 'สีเหลือง'
            },
            {
                key: 'Pink',
                border: '#E8A8A8',
                background: '#F8E3E2',
                name: 'สีชมพู'
            },
            {
                key: 'Green',
                border: '#89BD88',
                background: '#E9F3E1',
                name: 'สีเขียว'
            },
            {
                key: 'Orange',
                border: '#E7A588',
                background: '#FBE7DC',
                name: 'สีส้ม'
            },
            {
                key: 'Blue',
                border: '#A5BEE4',
                background: '#E2E8F1',
                name: 'สีฟ้า'
            },
            {
                key: 'Purple',
                border: '#C6A2E2',
                background: '#E8DEEF',
                name: 'สีม่วง'
            },
        ]

        const selectedColorOption = ref(null);

        const onClose = (e) => {
            context.emit("close")
        }

        const submit = (colorOption) => {
            context.emit('submit', { color: colorOption.border });
        }

        const submitClearColor = () => {
            context.emit('submit', { color: null });
        }

        const note = ref(null);

        const submitNote = () => {
            context.emit('submit-note', props.modelValue);
        }

        return { onClose, colorOptions, selectedColorOption, submit, submitClearColor, note, submitNote };
    },
};
</script>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #fff;
}

.modal__title {
    font-size: 1.5rem;
    font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>