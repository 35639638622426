<template>
  <div class="flex px-8">
    <div v-for="tab in tabs">
      <router-link :to="{ name: 'subjectMasterData', query: { tab: tab.key } }">
        <top-sub-menu-item :active="tab.active" :title="tab.title" />
      </router-link>
    </div>
  </div>

  <div v-for="tab in tabs" class="mt-4 px-8">
    <data-table
      v-if="$route.query.tab === tab.key"
      :name="tab.key"
      :columns="tab.columns"
      :filter="tab.filter"
    >
    </data-table>
  </div>
</template>

<script>
import TopSubMenuItem from "@/components/TopSubMenuItem.vue";
import DataTable from "@/components/DataTable";

import { useRoute } from "vue-router";
import { computed } from "@vue/runtime-core";
import {
  useCourseColumns,
  useCourseTypeColumns,
  useCreditColumns,
} from "@/composables/data-column";

import { useDataService } from "@/composables/data-service";

import axios from "axios";

export default {
  components: {
    TopSubMenuItem,
    DataTable,
  },
  setup() {
    const route = useRoute();

    const { courseTableColumns } = useCourseColumns();
    const { courseTypeTableColumns } = useCourseTypeColumns();
    const { creditTableColumns } = useCreditColumns();

    const { degrees } = useDataService();

    const tabs = computed(() => {
      return [
        {
          title: "วิชา",
          key: "course",
          active: route.query.tab == "course",
          columns: courseTableColumns.value,
          filter: {
            selects: [
              {
                options: degrees.value,
                label: "abbreviationTh",
                inputLabel: "สาขา",
                width: "350px",
                filterFunction: (subject, degree) => {
                  return subject?.degree?.id == degree?.id;
                },
              },
            ],
            searches: [
              {
                width: "350px",
                inputLabel: "รหัสวิชา",
                filterFunction: (subject, code) => {
                  return subject?.code?.includes(code);
                },
              },
            ],
          },
        },
        {
          title: "ประเภทวิชา",
          key: "course-type",
          active: route.query.tab == "course-type",
          columns: courseTypeTableColumns,
        },
        {
          title: "หน่วยกิต",
          key: "credit",
          active: route.query.tab == "credit",
          columns: creditTableColumns.value,
        },
      ];
    });

    return {
      tabs,
    };
  },
};
</script>
