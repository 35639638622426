import axios from "axios";
import EnvProvider from 'jvjr-docker-env';

axios.defaults.baseURL = EnvProvider.value('API_URL');

axios.defaults.headers.common = {
  Accept: "application/json",
};

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
