<template>
  <div class="flex items-center py-3 px-3 hover:bg-gray"><slot /> {{ title }}</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>
