<template>
  <Teleport to="body">
    <div
      class="no-select fixed bottom-10 right-10 z-20 flex cursor-pointer flex-col items-end"
    >
      <Transition name="fade-up">
        <div
          @click="showMenu = false"
          v-show="showMenu"
          class="w-42 mb-4 rounded-md bg-white text-sm shadow-md"
        >
          <slot />
        </div>
      </Transition>

      <div
        @click="showMenu = !showMenu"
        data-cy="fab" 
        class="flex h-14 w-14 cursor-pointer items-center justify-center rounded-full text-5xl font-extralight text-white"
        :class="[
          !showMenu
            ? 'bg-primary hover:bg-hover'
            : 'bg-red-500 hover:bg-red-400',
        ]"
      >
        <CloseIcon
          class="transform text-2xl text-white duration-200"
          :class="[!showMenu ? 'rotate-45' : 'rotate-90']"
        />
      </div>
    </div>
  </Teleport>
</template>

<script>
import CloseIcon from "@/assets/icon/close.svg";
import FabItem from "@/components/fab/FabItem";

import { reactive, ref } from "@vue/reactivity";

export default {
  components: {
    CloseIcon,
    FabItem,
  },
  setup() {
    let showMenu = ref(false);

    return {
      showMenu,
    };
  },
};
</script>

<style scoped>
.fade-up-leave-active {
  display: none;
}

.fade-up-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.fade-up-enter-active {
  transition: all 0.5s ease;
}

.fade-up-enter-to {
  opacity: 100;
}
</style>
