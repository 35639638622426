import { ref, onMounted, onUnmounted, computed } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";

import axios from "axios";

export function useCourseColumns() {
  onMounted(() => {
    updateOptions();
  });

  const courseTypes = ref([]);
  const credits = ref([]);
  const degrees = ref([]);

  const fetchCourseTypes = async () => {
    try {
      const response = await axios.get("course-type");

      courseTypes.value = response.data.data;
    } catch (error) {}
  };

  const fetchCredit = async () => {
    try {
      const response = await axios.get("credit");

      credits.value = response.data.data;
    } catch (error) {}
  };

  const fetchDegree = async () => {
    try {
      const response = await axios.get("degree");

      degrees.value = response.data.data;
    } catch (error) {}
  };

  const updateOptions = async () => {
    fetchCourseTypes();
    fetchCredit();
    fetchDegree();
  };

  const courseTableColumns = computed(() => [
    {
      title: "รหัสวิชา",
      keys: ["code"],
      width: "120px",
      input: "text",
    },
    {
      title: "ชื่อวิชา",
      keys: ["nameTh", "nameEn"],
      placeholders: ["ชื่อภาษาไทย", "ชื่อภาษาอังกฤษ"],
      width: "300px",
      input: "text",
    },
    {
      title: "หลักสูตร",
      keys: ["degree"],
      width: "150px",
      input: "select",
      options: degrees.value,
      labelKey: "abbreviationTh",
    },
    {
      title: "ประเภท",
      keys: ["courseType"],
      width: "220px",
      input: "select",
      options: courseTypes.value,
    },
    {
      title: "หน่วยกิต",
      keys: ["credit"],
      width: "220px",
      input: "select",
      options: credits.value,
    },
  ]);

  onBeforeRouteUpdate((to, from) => {
    updateOptions();
  });

  return {
    courseTableColumns,

    updateOptions,
  };
}

export function useCourseTypeColumns() {
  const courseTypeTableColumns = [
    {
      title: "ชื่อประเภทวิชา",
      keys: ["name"],
      width: "200px",
      input: "text",
    },
  ];

  return {
    courseTypeTableColumns,
  };
}

export function useCreditColumns() {
  onMounted(() => {
    updateOptions();
  });

  const specialTypes = ref([]);

  const fetchSpecialType = async () => {
    try {
      const response = await axios.get("special-type");

      specialTypes.value = response.data.data;
    } catch (error) {}
  };

  const updateOptions = async () => {
    fetchSpecialType();
  };

  const creditTableColumns = computed(() => [
    {
      title: "หน่วยกิต",
      keys: ["total"],
      width: "200px",
      input: "text",
    },
    {
      title: "บรรยาย",
      keys: ["lec"],
      width: "200px",
      input: "text",
    },
    {
      title: "ปฏิบัติ",
      keys: ["lab"],
      width: "200px",
      input: "text",
    },
    {
      title: "ศึกษาด้วยตนเอง",
      keys: ["selfStudy"],
      width: "200px",
      input: "text",
    },
    {
      title: "รูปแบบการสอน",
      keys: ["specialType"],
      width: "220px",
      input: "select",
      options: specialTypes.value,
    },
  ]);

  onBeforeRouteUpdate((to, from) => {
    updateOptions();
  });

  return {
    creditTableColumns,
  };
}

export function useMajorColumns() {
  const degreeTableColumns = computed(() => [
    {
      title: "ชื่อหลักสูตร",
      keys: ["nameTh", "nameEn"],
      placeholders: ["ชื่อภาษาไทย", "ชื่อภาษาอังกฤษ"],
      width: "300px",
      input: "text",
    },
    {
      title: "ชื่อย่อหลักสูตร",
      keys: ["abbreviationTh", "abbreviationEn"],
      placeholders: ["ชื่อย่อภาษาไทย", "ชื่อย่อภาษาอังกฤษ"],
      width: "300px",
      input: "text",
    },
  ]);

  return {
    degreeTableColumns,
  };
}

export function useRoomColumns() {
  onMounted(() => {
    updateOptions();
  });

  const roomTypes = ref([]);
  const buildings = ref([]);

  const roomTypeTableColumns = computed(() => [
    {
      title: "ประเภทห้อง",
      keys: ["name"],
      width: "200px",
      input: "text",
    },
  ]);

  const buildingTableColumns = computed(() => [
    {
      title: "ชื่ออาคาร",
      keys: ["name"],
      width: "200px",
      input: "text",
    },
  ]);

  const roomTableColumns = computed(() => [
    {
      title: "รหัส",
      keys: ["code"],
      width: "200px",
      input: "text",
    },
    {
      title: "ชื่อ",
      keys: ["name"],
      width: "200px",
      input: "text",
    },
    {
      title: "ชั้น",
      keys: ["floor"],
      width: "100px",
      input: "text",
    },
    {
      title: "ความจุ(คน)",
      keys: ["capacity"],
      width: "100px",
      input: "text",
    },
    {
      title: "อุปกรณ์ภายในห้อง",
      keys: ["tools"],
      width: "250px",
      input: "text",
    },
    {
      title: "ประเภท",
      keys: ["roomType"],
      width: "200px",
      input: "select",
      options: roomTypes.value,
    },
    {
      title: "อาคาร",
      keys: ["building"],
      width: "200px",
      input: "select",
      options: buildings.value,
    },
    {
      title: "ไฟล์",
      keys: ["imgUrl"],
      width: "100px",
      input: "image",
    },
  ]);

  const fetchRoomTypes = async () => {
    try {
      const response = await axios.get("room-type");

      roomTypes.value = response.data.data;
    } catch (error) {}
  };

  const fetchBuildings = async () => {
    try {
      const response = await axios.get("building");

      buildings.value = response.data.data;
    } catch (error) {}
  };

  const updateOptions = async () => {
    fetchRoomTypes();
    fetchBuildings();
  };

  return {
    roomTypeTableColumns,
    roomTableColumns,
    buildingTableColumns,
  };
}

export function useSemesterColumns() {
  const semesterTableColumns = computed(() => [
    {
      title: "ปีการศึกษา",
      keys: ["year"],
      width: "200px",
      input: "text",
    },
    {
      title: "ภาคการศึกษา",
      keys: ["name"],
      width: "200px",
      input: "selectText",
      options: ["ภาคเรียนที่ 1", "ภาคเรียนที่ 2", "ภาคฤดูร้อน"],
    },
    {
      title: "วันเปิดภาคเรียน",
      keys: ["startDate"],
      width: "200px",
      input: "date",
    },
    {
      title: "วันปิดภาคเรียน",
      keys: ["finishDate"],
      width: "200px",
      input: "date",
    },
  ]);

  return {
    semesterTableColumns,
  };
}

export function useUserColumns() {
  onMounted(() => {
    updateOptions();
  });

  const roles = ref([]);
  const userTypes = ref([]);
  const namePrefixes = ref([]);
  const degrees = ref([]);

  const userTypeTableColumns = computed(() => [
    {
      title: "ชื่อประเภทผู้ใช้งาน",
      keys: ["name"],
      width: "200px",
      input: "text",
    },
  ]);

  const namePrefixColumns = computed(() => [
    {
      title: "คำนำหน้า",
      keys: ["name"],
      width: "200px",
      input: "text",
    },
  ]);

  const userColumns = computed(() => [
    {
      title: "คำนำหน้า",
      keys: ["namePrefix"],
      width: "200px",
      input: "select",
      options: namePrefixes.value,
    },
    {
      title: "ชื่อจริง",
      keys: ["firstname"],
      width: "200px",
      input: "text",
    },
    {
      title: "นามสกุล",
      keys: ["lastname"],
      width: "200px",
      input: "text",
    },
    {
      title: "อีเมล",
      keys: ["email"],
      width: "200px",
      input: "text",
    },
    {
      title: "สาขา",
      keys: ["degree"],
      width: "200px",
      input: "select",
      options: degrees.value,
      labelKey: "abbreviationTh",
    },
    {
      title: "ประเภทผู้ใช้งาน",
      keys: ["userType"],
      width: "200px",
      input: "select",
      options: userTypes.value,
    },
    {
      title: "สิทธิ์การใช้งาน",
      keys: ["role"],
      width: "200px",
      input: "select",
      options: roles.value,
    },
  ]);

  const fetchNamePrefixes = async () => {
    try {
      const response = await axios.get("name-prefix");

      namePrefixes.value = response.data.data;
    } catch (error) {}
  };

  const fetchUserTypes = async () => {
    try {
      const response = await axios.get("user-type");

      userTypes.value = response.data.data;
    } catch (error) {}
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get("role");

      roles.value = response.data.data;
    } catch (error) {}
  };

  const fetchDegrees = async () => {
    try {
      const response = await axios.get("degree");

      degrees.value = response.data.data;
    } catch (error) {}
  };

  const updateOptions = async () => {
    fetchUserTypes();
    fetchRoles();
    fetchNamePrefixes();
    fetchDegrees();
  };

  return {
    userTypeTableColumns,
    namePrefixColumns,
    userColumns,
  };
}
