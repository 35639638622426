import { ref, onMounted, onUnmounted } from "vue";

export function columnsToObject(columns) {
  let obj = {};

  columns.forEach((column) => {
    column.keys.forEach((key) => (obj[key] = null));
  });

  return obj;
}

export function generateEmptyObjects(columns, rows) {
  let emptyData = columnsToObject(columns);

  let tempArray = [];

  for (let index = 0; index < rows; index++) {
    tempArray.push({ ...emptyData });
  }

  return tempArray;
}

export function optionObjectToId(dataAdding) {
  const clean = dataAdding.map((row) => {
    let obj = {};

    Object.keys(row).forEach((key) => {
      // Convert whole option object to id before sending to web service
      if (row[key] != null && row[key].hasOwnProperty("id")) {
        obj[`${key}Id`] = row[key].id;
      } else {
        obj[key] = row[key];
      }
    });

    return obj;
  });

  return clean;
}

export function getChangedData(newData, originalData) {
  const editedData = [...newData]
    // .filter((d) => d.edited)
    .map((d) => {
      const tempObject = { ...d };
      delete tempObject.editing;
      delete tempObject.edited;
      delete tempObject.selected;

      return tempObject;
    });

  let changedData = editedData.filter(
    (data) =>
      !originalData
        .map((source) => JSON.stringify(source))
        .includes(JSON.stringify(data))
  );

  return changedData;
}
