<template>
  <vue-final-modal
    :name="name"
    v-model="show"
    classes="modal-container"
    content-class="modal-content"
    :min-width="150"
  >
    <div class="w-90 my-4">
      <p class="text-center">{{ title }}</p>

      <div class="mt-4 flex justify-center">
        <base-button
          @click="
            () => {
              $emit('cancel');
              $vfm.hide(name);
            }
          "
          :data-cy="`${name}-cancel-button`"
          type="outline"
        >
          {{ cancelText }}</base-button
        >

        <base-button
          @click="
            () => {
              $emit('confirm');
              $vfm.hide(name);
            }
          "
          type="danger"
          :data-cy="`${name}-confirm-button`"
          >{{ confirmText }}</base-button
        >
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import { ref } from "vue";

export default {
  components: {
    BaseButton,
  },
  props: {
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    confirmText: {
      type: String,
    },
    cancelText: {
      type: String,
    },
  },
  emits: ["confirm", "cancel"],
  setup(props) {
    let show = ref(false);

    return {
      show,
    };
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0 1rem;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
