<template>
  <div class="side-menu-item" :class="[active ? 'active' : '']">
    <div class="slot">
      <slot></slot>
    </div>
    <span v-if="mode == 'expanded'" class="ml-3">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    active: Boolean,
    mode: {
      type: String,
      default: "expanded",
    },
  },
  setup(props) {},
};
</script>

<style scoped>
* {
  @apply cursor-pointer;
}

.side-menu-item {
  font-size: 15px;
  padding: 9px 4px;

  @apply mb-8 flex w-full items-center  px-4 font-medium text-blur;
}

.side-menu-item.active {
  @apply bg-accent text-primary;
}

.side-menu-item:hover:not(.active) {
  @apply text-hover;

  box-shadow: 0px 0px 2px 1px rgba(145, 145, 145, 0.15);
}

.router-link-active * {
  @apply bg-accent text-primary;
}

.slot:only-child {
  @apply mx-auto;
}
</style>
