<template>
  <div style="height: 88px; font-size: 29px"
    class="flex w-full items-center px-10 font-medium text-white">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    active: Boolean,
  },
  setup(props) {
    const start = process.env.VUE_APP_COLOR_GRADIENT_START;
    const end = process.env.VUE_APP_COLOR_GRADIENT_END;


    return {
      start,
      end
    }
  },
};
</script>

<style scoped>
div {
  background: linear-gradient(90deg, v-bind(start) 14.58%, v-bind(end) 100%);
}
</style>
