import { ref, onMounted, onUnmounted, reactive } from "vue";

import dayjs from "dayjs";
var isBetween = require("dayjs/plugin/isBetween");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export function useScheduleTable() {
  const timeSlots = reactive([
    dayjs("08:00", "HH:mm"),
    dayjs("08:30", "HH:mm"),
    dayjs("09:00", "HH:mm"),
    dayjs("09:30", "HH:mm"),
    dayjs("10:00", "HH:mm"),
    dayjs("10:30", "HH:mm"),
    dayjs("11:00", "HH:mm"),
    dayjs("11:30", "HH:mm"),
    dayjs("12:00", "HH:mm"),
    dayjs("12:30", "HH:mm"),
    dayjs("13:00", "HH:mm"),
    dayjs("13:30", "HH:mm"),
    dayjs("14:00", "HH:mm"),
    dayjs("14:30", "HH:mm"),
    dayjs("15:00", "HH:mm"),
    dayjs("15:30", "HH:mm"),
    dayjs("16:00", "HH:mm"),
    dayjs("16:30", "HH:mm"),
    dayjs("17:00", "HH:mm"),
    dayjs("17:30", "HH:mm"),
    dayjs("18:00", "HH:mm"),
    dayjs("18:30", "HH:mm"),
    dayjs("19:00", "HH:mm"),

  ]);

  const getSchedule = (time, schedules) => {
    return schedules.find((c) => {
      return time.isSame(dayjs(c.start, "HH:mm"), "minute");
    });
  };

  const checkTimeBetween = (time, schedules) => {
    return schedules.find((c) => {
      return time.isBetween(
        dayjs(c.start, "HH:mm"),
        dayjs(c.end, "HH:mm"),
        "minute",
        "[)"
      );
    });
  };

  return {
    timeSlots,
    getSchedule,
    checkTimeBetween,
  };
}
