import { createRouter, createWebHistory } from "vue-router";

import ClassScheduleLayout from "@/views/layouts/ClassScheduleLayout.vue";
import ClassScheduleDegree from "@/views/class-schedule/ClassScheduleDegree.vue";
import ClassScheduleDegreeStudentYear from "@/views/class-schedule/ClassScheduleDegreeStudentYear.vue";
import ClassScheduleSemester from "@/views/class-schedule/ClassScheduleSemester.vue";
import ClassScheduleTeacher from "@/views/class-schedule/ClassScheduleTeacher.vue";

import RoomScheduleLayout from "@/views/layouts/RoomScheduleLayout.vue";
import RoomSchedule from "@/views/room-schedule/RoomSchedule.vue";
import RoomScheduleSemester from "@/views/room-schedule/RoomScheduleSemester.vue";

import MainLayout from "@/views/layouts/MainLayout.vue";
import MasterDataLayout from "@/views/layouts/MasterDataLayout.vue";
import TransactionalDataLayout from "@/views/layouts/TransactionalDataLayout.vue";

import SubjectMasterDataLayout from "@/views/layouts/SubjectMasterDataLayout.vue";
import MajorMasterDataLayout from "@/views/layouts/MajorMasterDataLayout.vue";
import RoomMasterDataLayout from "@/views/layouts/RoomMasterDataLayout.vue";
import SemesterMasterDataLayout from "@/views/layouts/SemesterMasterDataLayout.vue";
import UserMasterDataLayout from "@/views/layouts/UserMasterDataLayout.vue";

import CourseMatchingPage from "@/views/CourseMatchingPage.vue";

import LoginPage from "@/views/LoginPage.vue";
import LoginExternalPage from "@/views/LoginExternalPage.vue";
import CallbackPage from "@/views/CallbackPage.vue";
import ImpersonateCallback from "@/views/ImpersonateCallback.vue";
import axios from "axios";
import store from "../vuex/store";

import EnvProvider from 'jvjr-docker-env';


import {
  checkAccessMasterData,
  checkAccessCourseMatching,
  checkAccessClassSchedule,
  checkAccessRoomSchedule,
  checkAccessWorkload,
} from "@/composables/authentication";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackPage,
  },
  {
    path: "/login-external",
    name: "login-external",
    component: LoginExternalPage
  },
  {
    path: '/auth/impersonate',
    name: 'impersonate-callback',
    component: ImpersonateCallback
  },
  {
    path: "/",
    name: "MainLayout",
    component: MainLayout,
    children: [
      {
        path: "/master-data",
        name: "masterData",
        component: MasterDataLayout,
        redirect: { name: "subjectMasterData", query: { tab: "course" } },
        children: [
          {
            path: "subject",
            name: "subjectMasterData",
            component: SubjectMasterDataLayout,
            meta: { masterLayout: "subject", topRoute: "masterData" },
          },
          {
            path: "major",
            name: "majorMasterData",
            component: MajorMasterDataLayout,
            meta: { masterLayout: "major", topRoute: "masterData" },
          },
          {
            path: "room",
            name: "roomMasterData",
            component: RoomMasterDataLayout,
            meta: { masterLayout: "room", topRoute: "masterData" },
          },
          {
            path: "semester",
            name: "semesterMasterData",
            component: SemesterMasterDataLayout,
            meta: { masterLayout: "semester", topRoute: "masterData" },
          },
          {
            path: "user",
            name: "userMasterData",
            component: UserMasterDataLayout,
            meta: { masterLayout: "user", topRoute: "masterData" },
          },
        ],
      },
      {
        path: "/transactional-data",
        name: "transactionalData",
        component: TransactionalDataLayout,
        redirect: { name: "courseMatching" },
        children: [
          {
            path: "course-matching",
            name: "courseMatching",
            component: CourseMatchingPage,
            meta: {
              masterLayout: "courseMatching",
              topRoute: "transactionalData",
            },
          },
        ],
      },
      {
        path: "/class-schedule",
        name: "classSchedule",
        component: ClassScheduleLayout,
        redirect: { name: "classScheduleSemester" },
        children: [
          {
            path: "semester",
            name: "classScheduleSemester",
            component: ClassScheduleSemester,
            meta: {
              masterLayout: "classScheduleSemester",
              topRoute: "classSchedule",
            },
          },
          {
            path: "degree",
            name: "classScheduleDegree",
            component: ClassScheduleDegree,
            meta: {
              masterLayout: "classScheduleDegree",
              topRoute: "classSchedule",
            },
          },
          {
            path: "degree-student-year",
            name: "classScheduleDegreeStudentYear",
            component: ClassScheduleDegreeStudentYear,
            meta: {
              masterLayout: "classScheduleDegreeStudentYear",
              topRoute: "classSchedule",
            },
          },
          {
            path: "degree-teecher",
            name: "classScheduleTeacher",
            component: ClassScheduleTeacher,
            meta: {
              masterLayout: "classScheduleTeacher",
              topRoute: "classSchedule",
            },
          },
          {
            path: "semester",
            name: "classScheduleSemester",
            component: ClassScheduleSemester,
            meta: {
              masterLayout: "classScheduleSemester",
              topRoute: "classSchedule",
            },
          },
        ],
      },
      {
        path: "/room-schedule",
        component: RoomScheduleLayout,
        redirect: { name: "roomSchedule" },
        children: [
          {
            path: "room",
            name: "roomSchedule",
            component: RoomSchedule,
            meta: { masterLayout: "roomSchedule", topRoute: "roomSchedule" },
          },
          {
            path: "semester",
            name: "roomScheduleSemester",
            component: RoomScheduleSemester,
            meta: { masterLayout: "roomScheduleSemester", topRoute: "roomSchedule" },
          },
        ],
      },

    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes,
});

router.beforeEach(async (to, from) => {
  if (to.name === "login" || to.name == "callback" || to.name == "login-as" || to.name == "login-external" || to.name == "impersonate-callback") {
    return true;
  }

  // Set outside token then check it after that
  if (to?.redirectedFrom?.query?.token || to?.query?.token) {
    const token = to?.redirectedFrom?.query?.token ?? to?.query?.token

    localStorage.setItem("token", token);
  }

  try {
    const response = await axios.post("auth/check");
    const user = response.data.data;

    store.commit("updateAuthentication", user);

    if (to.meta.topRoute == "masterData") {
      if (!checkAccessMasterData(user)) {
        return from;
      }
    }

    if (to.meta.topRoute == "transactionalData") {
      if (!checkAccessCourseMatching(user)) {
        return from;
      }
    }

    if (to.meta.topRoute == "classSchedule") {
      if (!checkAccessClassSchedule(user)) {
        return from;
      }
    }

    if (to.meta.masterLayout == "roomSchedule") {
      if (!checkAccessRoomSchedule(user)) {
        return from;
      }
    }
  } catch (error) {
    return { name: "login" };
  }

  return true;
});

export default router;
