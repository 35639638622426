import { ref, onMounted, onUnmounted, watch } from "vue";
import { useDataService } from "@/composables/data-service.js";
import { useRouter, useRoute } from "vue-router";

export function useDefaultHeader(
  { semesters, degrees, teachers, rooms },
  route,
  router
) {
  let defaultHeaders = ref({});

  const degree =
    degrees?.value.find((degree) => degree.id == route.query.degreeId) ??
    degrees?.value[0];

  const semester =
    semesters?.value.find(
      (semester) => semester.id == route.query.semesterId
    ) ?? semesters?.value[semesters?.value?.length - 1];


  const teacher =
    teachers?.value.find((teacher) => teacher.id == route.query.teacherId) ??
    teachers?.value[0];

  const room =
    rooms?.value.find((room) => room.id == route.query.roomId) ??
    rooms?.value[0];

  const academicYear = route.query.academicYear ?? semester?.year;

  const studentYear = route.query.studentYear ?? 1;

  defaultHeaders.value = {
    semester,
    academicYear,
    degree,
    studentYear,
    teacher,
    room,
  };

  return defaultHeaders;
}
