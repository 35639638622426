<template>
    <div>
        <div v-if="loading">{{ message }}</div>
        <div v-else-if="error"
            class="error">{{ error }}</div>
        <div v-else
            class="success">{{ message }}</div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import EnvProvider from "jvjr-docker-env";

const router = useRouter()
const loading = ref(true)
const error = ref(null)
const message = ref('')

const handleImpersonation = async (token, signature, expires) => {
    if (token && signature && expires) {
        try {
            const adminBaseUrl = `${EnvProvider.value("ADMIN_BASE_URL")}`;
            const response = await axios.get(`${adminBaseUrl}/api/impersonate/frontend`, {
                params: { expires, token, signature }
            })

            localStorage.setItem('token', response.data.token)
            return true
        } catch (err) {
            console.error('Impersonation error:', err)
            throw new Error('Impersonation failed')
        }
    } else {
        throw new Error('Invalid impersonation parameters')
    }
}

onMounted(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    const signature = urlParams.get('signature')
    const expires = urlParams.get('expires')

    try {
        message.value = 'Impersonating user...'
        const success = await handleImpersonation(token, signature, expires)
        if (success) {
            message.value = 'Fetching room booking URL...'
            const roomBookingUrl = await fetchRoomBookingUrl()
            message.value = 'Redirecting to room booking...'
            window.location.href = roomBookingUrl
        } else {
            throw new Error('Impersonation failed')
        }
    } catch (err) {
        error.value = `${err.message}. Redirecting to class schedule...`
    } finally {
        loading.value = false
        if (!error.value) {
            message.value = 'Impersonation successful. Redirecting...'
        }
        setTimeout(() => router.replace({ name: "classSchedule" }), 2000)
    }
})

const emsBaseUrl = `${EnvProvider.value("EMS_BASE_URL")}`;
const emsExternalUrl = `${emsBaseUrl}login-external`;

const fetchRoomBookingUrl = async () => {
    try {
        const response = await axios.post("auth/magic-login-link", {
            redirect_path: "/room_booking",
            external_url: emsExternalUrl
        });
        const { data: { url, external_url } } = response.data;

        return `${external_url}?url=${url}`;
    } catch (error) {
        console.error('Error fetching room booking URL:', error);
        throw new Error('Failed to fetch room booking URL');
    }
}
</script>

<style scoped>
.error {
    color: red;
}

.success {
    color: green;
}
</style>