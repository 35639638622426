<template>
  <div
    class="flex w-32 flex-col items-center justify-center border-r border-blur"
    :class="containerClass"
    style="height:60px"
  >
    <p :class="textClass">{{ title }}</p>

    <div class="mt-2 h-1 w-4 rounded-lg" :class="dashClass"></div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: {
    active: Boolean,
    title: String,
  },
  setup(props) {
    const containerClass = computed(() => ({
      "bg-blank": props.active,
      "bg-backdrop": !props.active,
    }));

    const textClass = computed(() => ({
      "font-medium": props.active,
      "text-primary": props.active,

      "text-gray-darker": !props.active,
      "font-light": !props.active,
    }));

    const dashClass = computed(() => ({
      "bg-primary": props.active,
      "bg-blur": !props.active,
    }));

    return {
      containerClass,
      textClass,
      dashClass,
    };
  },
};
</script>

<style scoped>
* {
  @apply cursor-pointer;
}


div:hover:not(.active) {
  @apply bg-gray-lighter text-blur;
}
</style>
