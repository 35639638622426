<template>
  <table>
    <thead class="h-10 bg-gray text-xs text-primary">
      <th v-show="columns.number">ตอนที่</th>
      <th v-show="columns.code">รหัสวิชา</th>
      <th v-show="columns.courseName">กระบวนวิชา</th>
      <th v-show="columns.degree">หลักสูตร</th>
      <th v-show="columns.teacher"
        class="w-48">ผู้สอนหลัก</th>
      <th v-show="columns.coTeachers"
        class="w-48">ผู้สอนร่วม</th>
      <th v-show="columns.weekDay">วัน</th>
      <th v-show="columns.time">เวลาเรียน</th>
      <th v-show="columns.room">ห้อง</th>
      <th v-show="columns.capacity">จำนวนรับ</th>
      <th v-show="columns.isComplete">ยืนยันข้อมูล</th>
      <th v-show="columns.isComplete">สถานะทับซ้อน</th>
      <th v-show="columns.note">หมายเหตุ</th>
    </thead>
    <tbody>
      <tr v-for="section in sections">
        <td v-show="columns.number"
          class="py-4">
          {{ section.number }}
        </td>
        <td v-show="columns.code">
          {{ section.course?.code }}
        </td>
        <td v-show="columns.courseName">
          {{ section.course?.nameTh }}
        </td>
        <td v-show="columns.degree">
          {{ section.course?.degree?.abbreviationTh }}
        </td>
        <td v-show="columns.teacher">
          {{ section.teacher?.firstname }} {{ section.teacher.lastname }}
        </td>
        <td v-show="columns.coTeachers">
          <p v-for="teacher in section.coTeachers">
            {{ teacher?.firstname }} {{ teacher.lastname }}
          </p>
        </td>
        <td v-show="columns.weekDay">
          {{ section.weekDay?.join(", ") }}
        </td>

        <td v-show="columns.time">
          {{ section?.startTime }} - {{ section?.finishTime }}
        </td>

        <td v-show="columns.room">
          {{ section.rooms?.map((room) => room.name).join(", ") }}
        </td>

        <td v-show="columns.capacity">
          {{ section.capacity }}
        </td>

        <td v-show="columns.isComplete">
          <router-link :to="{
        name: 'courseMatching',
        query: {
          semesterId: section.semester.id,
          degreeId: section.degree.id,
          courseId: section.course.id,
        },
      }">
            <div v-if="section.isComplete"
              class="text-blur">
              {{ section.teacher?.firstname }}
              {{ section.teacher.lastname }} [{{ section?.startTime }} -
              {{ section?.finishTime }}]
            </div>
            <div v-else
              class="text-red-500">ยังไม่ได้กรอกข้อมูล</div>
          </router-link>
        </td>

        <td v-show="columns.note">
          <router-link class="text-red-500"
            :to="{
        name: 'courseMatching',
        query: {
          semesterId: section.semester.id,
          degreeId: section.degree.id,
          courseId: section.course.id,
        },
      }">
            <div v-show="section.teacherOverlapped.isOverlapped">
              อาจารย์ทับซ้อน
            </div>

            <div v-show="section.roomOverlapped.isOverlapped">ห้องทับซ้อน</div>
          </router-link>
        </td>

        <td v-show="columns.note">
          {{ section.note }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      default: [],
    },
    columns: {
      type: Object,
      default: {
        number: true,
        code: true,
        courseName: true,
        degree: true,
        teacher: true,
        coTeachers: true,
        weekDay: true,
        time: true,
        room: true,
        capacity: true,
        isComplete: true,
        note: true,
      },
    },
  },
};
</script>
