<template>
  <div
    :style="{
      maxWidth: schedules.length > 0 ? 'calc(100% - 250px)' : '100%',
    }"
  >
    <div class="flex justify-end">
      <BaseButton
        @click="download"
        data-cy="download-button"
        type="outline"
        title="ดาวน์โหลด"
      >
        <IconDownload />
      </BaseButton>
    </div>

    <class-schedule-filter-bar
      v-model="header"
      :items="{
        degree: false,
        academicYear: false,
        semester: true,
        teacher: false,
        room: false,
        studentYear: false,
        showTable: true,
      }"
      @update:modelValue="onFilterHeaderChange"
    />

    <div
      v-if="schedules.length > 0"
      class="section-container bg-gray-lighter p-2"
      style="box-shadow: 0px 0px 12px rgba(80, 86, 131, 0.2)"
    >
      <SectionTable
        v-show="!header.showTable"
        :columns="{
          number: true,
          code: true,
          courseName: true,
          degree: true,
          teacher: true,
          coTeachers: true,
          weekDay: true,
          time: true,
          room: true,
          capacity: true,
          isComplete: true,
          note: true,
        }"
        :sections="sections"
      >
      </SectionTable>

      <ScheduleTableWeekly v-show="header.showTable" :data="schedules">
        <template
          #itemSchedule="{
            titleRow,
            titleGroup,
            timeSlot,
            canAccessCourseMatching,
            slot,
          }"
        >
          <ScheduleItemDegree
            :data-cy="`schedule-item-start-${slot.start}-end-${slot.end}-weekday-${titleGroup}-roomId-${titleRow}-`"
            :semesterId="header.semester.id"
            :roomId="titleRow"
            :startTime="timeSlot"
            :weekDay="titleGroup"
            :canAccessCourseMatching="canAccessCourseMatching"
            :slot="slot"
          />
        </template>

        <template
          #itemFree="{
            titleRow,
            titleGroup,
            timeSlot,
            slot,
            canAccessCourseMatching,
          }"
        >
          <ScheduleItemFree
            :semesterId="header.semester.id"
            :roomId="titleRow"
            :startTime="timeSlot"
            :canAccessCourseMatching="canAccessCourseMatching"
          />
        </template>
      </ScheduleTableWeekly>
    </div>
  </div>
</template>

<script>
import SelectorButton from "@/components/SelectorButton";
import SectionTable from "@/components/SectionTable";
import ScheduleTableWeekly from "@/components/ScheduleTableWeekly";
import ScheduleItemFree from "@/components/ScheduleItemFree";
import ScheduleItemDegree from "@/components/ScheduleItemDegree";
import ClassScheduleFilterBar from "@/components/ClassScheduleFilterBar";
import BaseButton from "@/components/BaseButton";
import IconDownload from "@/assets/icon/download.svg";

import { useDataService } from "@/composables/data-service.js";
import { useDefaultHeader } from "@/composables/filter-bar.js";
import { useRouter, useRoute } from "vue-router";

import { computed, ref, watch } from "vue";
import { useLoading } from "vue-loading-overlay";

import axios from "axios";
import EnvProvider from "jvjr-docker-env";

export default {
  components: {
    SelectorButton,
    SectionTable,
    ScheduleTableWeekly,
    ScheduleItemFree,
    ScheduleItemDegree,
    ClassScheduleFilterBar,
    BaseButton,
    IconDownload,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const $loading = useLoading();

    let header = ref({
      degree: null,
      academicYear: null,
      semester: null,
      teacher: null,
      room: null,
      studentYear: 1,
      showTable: true,
    });

    let { semesters } = useDataService();

    let schedules = ref([]);
    let sections = ref([]);

    const onFilterHeaderChange = () => {
      if (header.value.semester) {
        updateRouteQueryString();

        fetchSections();
        fetchSchedules();
      }
    };

    const updateRouteQueryString = () => {
      const { semester, academicYear } = header.value;

      router.replace({
        name: "roomScheduleSemester",
        query: {
          semesterId: semester?.id,
          academicYear: academicYear,
        },
      });
    };

    const fetchSchedules = async () => {
      schedules.value = [];

      try {
        const loader = $loading.show({});
        const { room, semester } = header.value;

        const response = await axios.get(
          `room-schedule/semester/${semester.id}`
        );

        schedules.value = response.data.data;
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
    };

    const fetchSections = async () => {
      try {
        const { semester } = header.value;

        const response = await axios.get(`section?semesterId=${semester.id}`);

        sections.value = response.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const download = async () => {
      try {
        const { room, semester } = header.value;

        window.open(
          `${EnvProvider.value("BASE_API_URL")}room-schedule/semester/${
            semester.id
          }/excel`
        );
      } catch (error) {
        console.log(error);
      }
    };

    watch([semesters], () => {
      if (semesters.value.length) {
        const { semester, academicYear } = useDefaultHeader(
          { semesters },
          route,
          router
        ).value;

        header.value = {
          ...header.value,
          semester: semester,
          academicYear: academicYear,
        };

        updateRouteQueryString();
      }
    });

    return {
      schedules,
      sections,

      header,

      onFilterHeaderChange,
      download,
    };
  },
};
</script>
