<template>
  <div class="flex px-8">
    <div v-for="tab in tabs">
      <router-link :to="{ name: 'roomMasterData', query: { tab: tab.key } }">
        <top-sub-menu-item :active="tab.active" :title="tab.title" />
      </router-link>
    </div>
  </div>

  <div v-for="tab in tabs" class="mt-4 px-8">
    <data-table
      v-if="$route.query.tab === tab.key"
      :name="tab.key"
      :columns="tab.columns"
    >
    </data-table>
  </div>
</template>

<script>
import TopSubMenuItem from "@/components/TopSubMenuItem.vue";
import DataTable from "@/components/DataTable";

import { useRoute } from "vue-router";
import { computed } from "@vue/runtime-core";
import { useRoomColumns } from "@/composables/data-column";

import axios from "axios";

export default {
  components: {
    TopSubMenuItem,
    DataTable,
  },
  setup() {
    const route = useRoute();

    const { roomTypeTableColumns, roomTableColumns, buildingTableColumns } =
      useRoomColumns();

    const tabs = computed(() => {
      return [
        {
          title: "ห้อง",
          key: "room",
          active: route.query.tab == "room",
          columns: roomTableColumns.value,
        },
        {
          title: "อาคาร",
          key: "building",
          active: route.query.tab == "building",
          columns: buildingTableColumns.value,
        },
        {
          title: "ประเภทห้อง",
          key: "room-type",
          active: route.query.tab == "room-type",
          columns: roomTypeTableColumns.value,
        },
      ];
    });

    return {
      tabs,
    };
  },
};
</script>
