<template>
  <div>


    <div class="flex justify-end">
      <BaseButton @click="download"
        data-cy="download-button"
        type="outline"
        title="ดาวน์โหลด">
        <IconDownload />
      </BaseButton>
    </div>

    <class-schedule-filter-bar v-model="header"
      :items="{
        degree: true,
        academicYear: true,
        semester: true,
        teacher: false,
        room: false,
        studentYear: true,
        showTable: true,
      }"
      @update:modelValue="onFilterHeaderChange" />

    <div v-if="schedules.length > 0"
      class="section-container bg-gray-lighter p-2"
      style="box-shadow: 0px 0px 12px rgba(80, 86, 131, 0.2)">
      <SectionTable v-show="!header.showTable"
        :columns="{
        number: true,
        code: true,
        courseName: true,
        degree: false,
        teacher: true,
        coTeachers: true,
        weekDay: true,
        time: true,
        room: false,
        capacity: false,
        isComplete: true,
        note: true,
      }"
        :sections="sections">
      </SectionTable>

      <ScheduleTable v-show="header.showTable"
        class=""
        :data="schedules">
        <template #itemSchedule="{
        titleRow,
        titleGroup,
        timeSlot,
        canAccessCourseMatching,
        slot,
      }">
          <ScheduleItemDegree
            :data-cy="`schedule-item-start-${slot.start}-end-${slot.end}-weekday-${titleRow}-degreeId-${header?.degree?.id}-studentYear-${header.studentYear}`"
            :weekDay="titleRow"
            :studentYear="header.studentYear"
            :degreeId="header.degree.id"
            :semesterId="header.semester.id"
            :startTime="timeSlot"
            :canAccessCourseMatching="canAccessCourseMatching"
            :slot="slot" />
        </template>

        <template #itemFree="{
        titleRow,
        titleGroup,
        timeSlot,
        canAccessCourseMatching,
      }">
          <ScheduleItemFree :weekDay="titleRow"
            :studentYear="header.studentYear"
            :degreeId="header.degree.id"
            :semesterId="header.semester.id"
            :startTime="timeSlot"
            :canAccessCourseMatching="canAccessCourseMatching" />
        </template>
      </ScheduleTable>

      <div class="max-w-screen-md mt-8 mb-8">
        <div class="flex justify-between items-center mb-2">
          <div class="flex items-center">
            <p class="text-sm text-primary mb-2 mr-4">ข้อมูลเพิ่มเติม</p>

            <button v-if="noteEditing == false"
              @click="enableNoteEdit"
              class="h-8 items-center justify-center rounded-md border border-primary px-4 text-center text-sm "
              data-cy="btn-login">
              แก้ไข
            </button>
          </div>

          <div>
            <button v-if="noteEditing == true"
              @click="cancelNoteEdit"
              class="h-8 items-center justify-center rounded-md border border-primary px-4 text-center text-sm mr-4"
              data-cy="btn-login">
              ยกเลิก
            </button>
            <button v-if="noteEditing == true"
              @click="submitNote"
              class=" h-8 items-center justify-center rounded-md bg-primary px-4 text-center text-sm text-white"
              data-cy="btn-login">
              บันทึก
            </button>
          </div>



        </div>
        <textarea v-if="noteEditing == true"
          class="border border-gray-darker rounded-md w-full shadow-sm p-2"
          v-model="note"
          rows="7"
          placeholder="ข้อมูลเพิ่มเติม" />

        <p v-else
          class=" py-2">{{ note }}</p>
      </div>


    </div>
  </div>
</template>

<script>
import SelectorButton from "@/components/SelectorButton";
import SectionTable from "@/components/SectionTable";
import ScheduleTable from "@/components/ScheduleTable";
import ScheduleItemFree from "@/components/ScheduleItemFree";
import ScheduleItemDegree from "@/components/ScheduleItemDegree";

import ClassScheduleFilterBar from "@/components/ClassScheduleFilterBar";
import BaseButton from "@/components/BaseButton";
import IconDownload from "@/assets/icon/download.svg";

import { useDataService } from "@/composables/data-service.js";
import { useDefaultHeader } from "@/composables/filter-bar.js";

import { useRouter, useRoute } from "vue-router";

import { computed, onMounted, ref, watch } from "vue";
import EnvProvider from "jvjr-docker-env";
import { useToast } from "vue-toast-notification";
import axios from "axios";

export default {
  components: {
    SelectorButton,
    SectionTable,
    ScheduleTable,
    ScheduleItemFree,
    ScheduleItemDegree,
    ClassScheduleFilterBar,
    BaseButton,
    IconDownload,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();

    let sections = ref([]);
    let schedules = ref([]);

    let note = ref("");
    let noteEditing = ref("");

    let header = ref({
      degree: null,
      academicYear: null,
      semester: null,
      teacher: null,
      room: null,
      studentYear: 1,
      showTable: true,
    });

    let { semesters, degrees } = useDataService();

    onMounted(() => {
      fetchNote();
    })

    const onFilterHeaderChange = () => {
      if (
        header.value.degree &&
        header.value.studentYear &&
        header.value.semester
      ) {
        updateRouteQueryString();

        fetchSections();
        fetchSchedules();
        fetchNote();
      }
    };

    const updateRouteQueryString = () => {
      const { degree, semester, academicYear, studentYear } = header.value;

      router.replace({
        name: "classScheduleDegreeStudentYear",
        query: {
          semesterId: semester?.id,
          degreeId: degree?.id,
          academicYear: academicYear,
          studentYear: studentYear,
        },
      });
    };

    const fetchSchedules = async () => {
      schedules.value = [];

      try {
        const { degree, studentYear, semester } = header.value;

        const response = await axios.get(
          `class-schedule/degree/${degree?.id}/student-year/${studentYear}/semester/${semester.id}`
        );

        schedules.value = response.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const fetchSections = async () => {
      try {
        const { degree, studentYear, semester } = header.value;

        const response = await axios.get(
          `section?degreeId=${degree.id}&semesterId=${semester.id}&studentYear=${studentYear}`
        );

        sections.value = response.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const download = async () => {
      try {
        const { degree, studentYear, semester } = header.value;

        window.open(
          `${EnvProvider.value("BASE_API_URL")}class-schedule/degree/${degree?.id
          }/student-year/${studentYear}/semester/${semester.id}/excel`
        );
      } catch (error) {
        console.log(error);
      }
    };

    const enableNoteEdit = () => {
      noteEditing.value = true;
    }

    const cancelNoteEdit = () => {
      noteEditing.value = false;
    }

    const fetchNote = async () => {
      try {
        const { studentYear, semester, degree } = header.value;

        if (studentYear != null && semester != undefined && degree != undefined) {
          const response = await axios.get(
            `class-schedule-note`, {
            params: {
              "semester_id": semester?.id,
              "student_year": studentYear,
              "degree_id": degree?.id,
            }
          }
          );

          note.value = response.data?.data?.content ?? "";
        }



      } catch (error) {
        console.log(error);
      }
    };

    const submitNote = async () => {
      noteEditing.value = false;

      const { semester, studentYear, degree } = header.value;


      try {
        const response = await axios.post(
          `class-schedule-note`, {
          "semester_id": semester?.id,
          "student_year": studentYear,
          "degree_id": degree.id,
          "content": note.value,
        }
        );
        useToast().success("บันข้อมูลแล้ว");

      } catch (error) {
        useToast().error("บันข้อมูลไม่สำเร็จ");

      }
    }

    watch([semesters, degrees], () => {
      if (semesters.value.length > 0 && degrees.value.length > 0) {
        const { semester, academicYear, degree, studentYear } =
          useDefaultHeader({ semesters, degrees }, route, router).value;

        header.value = {
          ...header.value,
          degree: degree,
          semester: semester,
          academicYear: academicYear,
          studentYear: studentYear,
        };

        updateRouteQueryString();
      }
    });

    return {
      schedules,
      sections,

      note,
      noteEditing,
      enableNoteEdit,
      cancelNoteEdit,
      submitNote,

      header,

      onFilterHeaderChange,
      download,
    };
  },
};
</script>
