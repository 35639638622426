<template>
  <div :data-cy="`section-group-${type.toLowerCase()}`">
    <SectionTitle
      :title="`${type == 'LEC' ? 'ตอนบรรยาย' : 'ตอนปฏิบัติการ'}`"
    ></SectionTitle>

    <div class="flex justify-between">
      <div class="flex items-center">
        <p data-cy="total-number" class="text-sm text-primary">
          {{ modelValue.length }} รายการ
        </p>
        <p data-cy="selected-number" class="ml-2 text-sm text-gray-darker">
          (เลือก 0 รายการ)
        </p>
      </div>

      <BaseButton
        @click="addSection(sections)"
        data-cy="add-section-button"
        type="outline"
        title="เพิ่มตอน"
      >
        <IconAdd class="text-primary"></IconAdd>
      </BaseButton>
    </div>

    <div class="mt-4">
      <table class="table-header">
        <tr class="rounded-md bg-gray">
          <th class="py-3" style="width: 50px">
            <input
              data-cy="select-all-checkbox"
              type="checkbox"
              true-value="true"
              false-value="false"
            />
          </th>

          <th class="w-24 px-2" data-cy="section-th-number">ตอน</th>
          <th
            v-if="
              ![
                'INTERN',
                'IS',
                'COOP',
                'B_THESIS',
                'M_THESIS',
                'PHD_THESIS',
              ].includes(specialType)
            "
            class="w-48 px-2"
            data-cy="section-th-week-days"
          >
            วัน
          </th>
          <th
            v-if="
              ![
                'INTERN',
                'IS',
                'COOP',
                'B_THESIS',
                'M_THESIS',
                'PHD_THESIS',
              ].includes(specialType)
            "
            class="w-72 px-2"
            data-cy="section-th-time"
          >
            เวลาเรียน
          </th>
          <th
            v-if="
              ![
                'INTERN',
                'IS',
                'COOP',
                'B_THESIS',
                'M_THESIS',
                'PHD_THESIS',
              ].includes(specialType)
            "
            class="w-64 px-2"
            data-cy="section-th-room"
          >
            ห้อง
          </th>
          <th class="w-4"></th>
          <th class="w-64 px-2" data-cy="section-th-teacher">
            {{
              teacherColumnTitles.teacher[specialType] ?? "อาจารย์ผู้สอนหลัก"
            }}
          </th>

          <th
            v-if="
              ['IS', 'COOP', 'B_THESIS', 'M_THESIS', 'PHD_THESIS'].includes(
                specialType
              )
            "
            class="w-64 px-2"
            data-cy="section-th-teachers"
          >
            {{
              teacherColumnTitles.teachers[specialType] ?? "อาจารย์ผู้สอนหลัก"
            }}
          </th>
          <th class="w-32 px-2" data-cy="section-th-capacity">จำนวนรับ</th>
          <th class="w-32 px-2" data-cy="section-th-registered-number">
            ลงทะเบียน
          </th>

          <th></th>
        </tr>
      </table>

      <div class="">
        <table>
          <div
            :data-cy="`section-row-${index}`"
            class="mt-2 rounded-md bg-white pb-4"
            style="box-shadow: 0px 0px 4px rgba(80, 86, 131, 0.2)"
            v-for="(section, index) in modelValue"
          >
            <SectionInputRow
              @delete="deleteSection(index)"
              @duplicate="duplicateSection(index)"
              @duplicate-across="duplicateSectionAcross(index)"
              @update:modelValue="onChange"
              v-model="modelValue[index]"
              :type="type"
              :special-type="specialType"
              :rooms="rooms"
              :teachers="teachers"
              :semesters="semesters"
              :degrees="degrees"
              :courses="courses"
            ></SectionInputRow>
          </div>
        </table>
      </div>

      <table class="mt-4 border-separate">
        <tr class="py-8">
          <th class="py-3" style="width: 50px"></th>
          <th class="w-24 px-2"></th>
          <th class="w-48 px-2"></th>
          <th class="w-72 px-2"></th>
          <th class="w-64 px-2"></th>
          <th class="w-4"></th>
          <th class="w-64 px-2 text-right">จำนวนรวม</th>
          <th class="w-32 px-2">
            <input
              disabled
              class="input-text"
              type="text"
              :value="totalCapacity"
            />
          </th>
          <th class="w-32 px-2">
            <input
              disabled
              class="input-text"
              type="text"
              :value="totalRegisteredNumber"
            />
          </th>

          <th></th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import SectionInputRow from "@/components/SectionInputRow";
import SectionTitle from "@/components/SectionTitle";

import IconAdd from "@/assets/icon/add.svg";
import IconSave from "@/assets/icon/save.svg";
import IconDelete from "@/assets/icon/delete.svg";

import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  requiredIf,
  email,
  decimal,
} from "@vuelidate/validators";

import {
  sectionObjectTemplate,
  degreeObjectTemplate,
} from "@/ultils/course-matching-utils.js";
import { v4 as uuidv4 } from "uuid";

import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";

export default {
  components: {
    SectionTitle,
    BaseButton,
    IconAdd,
    IconSave,
    IconDelete,
    SectionInputRow,
  },
  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: Array,
      default: [],
    },
    oppositeTypeSections: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
    },
    specialType: {
      type: String,
      default: "LEC_LAB",
    },
    rooms: {
      type: Array,
      default: [],
    },
    teachers: {
      type: Array,
      default: [],
    },
    semesters: {
      type: Array,
      default: [],
    },
    degrees: {
      type: Array,
      default: [],
    },
    courses: {
      type: Array,
      default: [],
    },
  },
  setup(props, context) {
    const addSection = () => {
      props.modelValue.push({
        ...sectionObjectTemplate,
        degrees: [{ ...degreeObjectTemplate }],
        id: null,
        inputId: uuidv4(),
      });
    };

    const duplicateSection = (index) => {
      props.modelValue.push({
        ...props.modelValue[index],
        degrees: props.modelValue[index].degrees.map((degree) => ({
          ...degree,
        })),
        id: null,
        inputId: uuidv4(),
      });
    };

    const duplicateSectionAcross = (index) => {
      const targetSection = props.modelValue[index];
      const oppositeType = targetSection.type == "LEC" ? "LAB" : "LEC";

      props.oppositeTypeSections.push({
        ...targetSection,
        type:oppositeType,
        degrees: targetSection.degrees.map((degree) => ({
          ...degree,
        })),
        id: null,
        inputId: uuidv4(),
      });

      useToast().success(`คัดลอกเซคชั่นไปที่ ${oppositeType} แล้ว`);
    };

    const deleteSection = (index) => {
      props.modelValue.splice(index, 1);
    };

    const totalCapacity = computed(() => {
      return props.modelValue.reduce(
        (prev, section) =>
          Number.parseInt(prev) + Number.parseInt(section?.capacity),
        0
      );
    });

    const totalRegisteredNumber = computed(() => {
      return props.modelValue.reduce(
        (prev, section) =>
          Number.parseInt(prev) + Number.parseInt(section?.registeredNumber),
        0
      );
    });

    const onChange = () => {
      context.emit("update:modelValue", props.modelValue);
    };

    const teacherColumnTitles = computed(() => {
      const types = {
        teacher: {
          COOP: "อาจารย์ผู้ประสานงาน",
          INTERN: "อาจารย์ผู้ประสานงาน",
          IS: "อาจารย์ผู้ประสานงาน",
          B_THESIS: "อาจารย์ผู้ประสานงาน",
          M_THESIS: "อาจารย์ที่ปรึกษาหลัก",
          PHD_THESIS: "อาจารย์ที่ปรึกษาหลัก",
        },
        teachers: {
          COOP: "อาจารย์นิเทศ",
          INTERN: "",
          IS: "อาจารย์ที่ปรึกษา",
          B_THESIS: "อาจารย์ที่ปรึกษา",
          M_THESIS: "อาจารย์ที่ปรึกษาร่วม",
          PHD_THESIS: "อาจารย์ที่ปรึกษาร่วม",
        },
      };

      return types;
    });

    // const v$ = useVuelidate();

    return {
      // sections,

      addSection,
      deleteSection,
      duplicateSection,
      duplicateSectionAcross,

      totalCapacity,
      totalRegisteredNumber,

      onChange,

      teacherColumnTitles,

      // v$,
    };
  },
};
</script>
