<template>
  <div class="flex rounded-md bg-backdrop py-1">
    <button
      v-for="option in options"
      @click="onOptionClick(option)"
      :data-cy="`selector-btn-${option.name}`"
      class="mx-1 h-8 rounded-md bg-white px-2 text-blur"
      :class="[modelValue.id == option.id ? 'bg-primary' : '']"
    >
      <span :class="[modelValue.id == option.id ? 'text-white' : '']"
        > {{ option.name }}</span
      >
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
    },
    options: {
      default: [],
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props, context) {
    const onOptionClick = (option) => {
      context.emit("update:modelValue", option);
      context.emit("change", option);
    };

    return {
      onOptionClick,
    };
  },
};
</script>
