<template>
  <div class="relative">
    <img src="@/assets/login-bg.png"
      alt=""
      class="absolute z-10 h-screen w-full" />
    <div class="absolute z-30 h-screen w-full bg-primary opacity-50"></div>
    <div class="absolute z-40 flex h-screen w-full items-center justify-center">
      <div class="h-auto w-96 bg-white px-12 py-12 text-center">
        <img class="mx-auto w-28"
          :src="logo"
          alt="" />
        <div class="mt-4">
          <p class="text-xl">ระบบจัดการศึกษา</p>
          <p class="text-sm">{{ orgName }}</p>
        </div>

        <button @click="loginWithCmu"
          class="mt-16 h-12 items-center justify-center rounded-md bg-primary px-4 text-center text-white"
          data-cy="btn-login">
          เข้าสู่ระบบด้วย CMU Account
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ArchLogo from "@/assets/logo.svg";
import BaseButton from "@/components/BaseButton";
import { useRouter, useRoute } from "vue-router";
import { onMounted } from "vue";


import axios from "axios";
import EnvProvider from "jvjr-docker-env";

export default {
  components: {
    ArchLogo,
    BaseButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const orgName = `${EnvProvider.value("ORG_NAME")}`;
    const logo = `${EnvProvider.value("LOGO_NO_TEXT_1_URL")}`;

    const loginWithCmu = async (event, to = null) => {
      event.preventDefault();

      let state = {
        redirectFrom: route.query.redirect_url,
        redirectToEmsUrl: "/",
      };

      let callbackUri = `${EnvProvider.value("BASE_URL")}callback`;
      let clientId = EnvProvider.value("CMU_CLIENT_ID");

      window.location.href = `https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=${clientId}&redirect_uri=${callbackUri}&scope=cmuitaccount.basicinfo&state=${JSON.stringify(
        state
      )}`;
    };

    return {
      orgName,
      logo,

      loginWithCmu,
    };
  },
};
</script>
