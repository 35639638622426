<template>
  <button
    class="flex h-9 items-center justify-center rounded-md px-4 text-center"
    :class="buttonClass"
  >
    <slot /> {{ title }}
  </button>
</template>

<script>
export default {
  props: {
    title: String,
    type: {
      type: String,
      default: "solid",
    },
  },
  setup(props) {
    const buttonClass = {
      solid: props.type == "solid",
      outline: props.type == "outline",
      danger: props.type == "danger",
    };

    return {
      buttonClass,
    };
  },
};
</script>

<style scoped>
button > :first-child {
  @apply mr-2;
}

.solid {
  @apply bg-primary text-white hover:bg-hover;
}

.outline {
  @apply border border-primary bg-white hover:border-hover hover:text-hover;
}

.danger {
  @apply bg-red-500 text-white hover:bg-red-400;
}

button {
  @apply ml-3;
  min-width: 100px;
}

:first-child {
  @apply ml-0;
}
</style>
