<template>
  <vue-final-modal
    name="addDataModal"
    v-model="show"
    classes="modal-container"
    content-class="modal-content"
    :min-width="200"
  >
    <div class="w-90">
      <p class="text-center">จำนวนแถว</p>

      <div class="mt-8 flex items-center justify-center">
        <button
          @click="decreseRow"
          data-cy="decrese-row-number-button"
          class="mr-6 flex h-6 w-6 items-center justify-center rounded-full bg-primary text-center text-lg text-white"
        >
          -
        </button>

        <input
          type="text"
          data-cy="row-number-input"
          v-model="rows"
          class="input-text mx-4 text-center"
          style="max-width: 100px"
        />

        <button
          @click="addRow"
          data-cy="increse-row-number-button"
          class="ml-6 flex h-6 w-6 items-center justify-center rounded-full bg-primary text-center text-lg text-white"
        >
          +
        </button>
      </div>

      <div class="mt-8 flex justify-center">
        <base-button
          @click="() => $vfm.hide('addDataModal')"
          data-cy="add-row-modal-cancel"
          type="outline"
          >ยกเลิก</base-button
        >

        <base-button
          @click="
            () => {
              $emit('submit', rows);
              $vfm.hide('addDataModal');
            }
          "
          data-cy="add-row-modal-confirm"
          >เพิ่ม</base-button
        >
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import { ref } from "vue";

export default {
  components: {
    BaseButton,
  },
  emits: ["submit"],
  setup(props) {
    let rows = ref(2);

    const addRow = () => {
      rows.value++;
    };

    const decreseRow = () => {
      if (rows.value > 1) rows.value--;
    };

    let show = ref(false);

    return {
      rows,

      addRow,
      decreseRow,

      show,
    };
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
