<template>
  <!-- <pre class="text-xs">
    {{ modelValue }}
  </pre> -->
  <div class="flex justify-between py-4">
    <div class="flex items-center">
      <div v-show="items.degree"
        class=""
        style="width: 450px">
        <p class="text-sm text-primary">หลักสูตร</p>

        <v-select @option:selected="onInputChange"
          dropdown-option-wrap="truncate"
          v-model="modelValue.degree"
          :data-cy="`degree`"
          :options="degrees"
          class="overflow-wrap whitespace-nowrap bg-white"
          label="abbreviationTh">
          <template #option="option">
            <div class="v-select-option">
              {{ option.abbreviationTh }}
            </div>
          </template>
        </v-select>
      </div>

      <div v-show="items.teacher"
        class=""
        style="width: 250px">
        <p class="text-sm text-primary">ผู้สอน</p>

        <v-select @option:selected="onInputChange"
          dropdown-option-wrap="truncate"
          v-model="modelValue.teacher"
          :data-cy="`teacher`"
          :options="teachers.filter(t => t.userType.key === 'TEACHER')"
          class="overflow-wrap whitespace-nowrap bg-white"
          :get-option-label="(teacher) => `${teacher.firstname} ${teacher.lastname}`
        ">
          <template #option="option">
            <div class="v-select-option">
              {{ option.firstname }} {{ option.lastname }}
            </div>
          </template>
        </v-select>
      </div>

      <div v-show="items.room"
        class=""
        style="width: 250px">
        <p class="text-sm text-primary">ห้อง</p>

        <v-select @option:selected="onInputChange"
          dropdown-option-wrap="truncate"
          v-model="modelValue.room"
          :data-cy="`room`"
          :options="rooms"
          class="overflow-wrap whitespace-nowrap bg-white"
          label="name">
          <template #option="option">
            <div class="v-select-option">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>

      <div v-show="items.studentYear"
        class="ml-4 w-24">
        <p class="text-sm text-primary">ชั้นปี</p>
        <v-select :data-cy="`studentYear`"
          v-model="modelValue.studentYear"
          :options="['1', '2', '3', '4', '5', 'GE']"
          @option:selected="onInputChange"
          class="bg-white" />
      </div>

      <div v-show="items.showTable"
        class="ml-4 flex items-center pt-5">
        <Toggle v-model="modelValue.showTable" />
        <p class="ml-2 text-sm text-primary">ตารางสอน</p>
      </div>
    </div>

    <div v-show="items.semester"
      class="flex items-center">
      <div class="ml-4 w-32">
        <p class="text-sm text-primary">ปีการศึกษา</p>
        <v-select @option:selected="onAcademicYearChange"
          class="w-full bg-white"
          v-model="modelValue.academicYear"
          :data-cy="`semester`"
          :options="academicYears" />
      </div>

      <div class="flex items-center justify-end"
        style="margin-top: 18px">
        <SelectorButton v-model="modelValue.semester"
          @change="onInputChange"
          :options="academicYearSemesters"></SelectorButton>
      </div>
    </div>
  </div>
</template>

<script>
import SelectorButton from "@/components/SelectorButton";
import SectionTable from "@/components/SectionTable";
import ScheduleTable from "@/components/ScheduleTable";
import Toggle from "@vueform/toggle";

import { useDataService } from "@/composables/data-service.js";
import { computed, ref, watch } from "vue";

export default {
  props: {
    modelValue: {
      type: Object,
      default: {},
    },
    items: {
      type: Object,
      default: {
        degree: true,
        academicYear: true,
        semester: true,
        teacher: false,
        room: false,
        studentYear: false,
        showTable: true,
      },
    },
  },
  emits: ["update:modelValue"],
  components: {
    SelectorButton,
    SectionTable,
    ScheduleTable,
    Toggle,
  },
  setup(props, context) {
    let { rooms, teachers, semesters, degrees, courses } = useDataService();

    // teachers.value = teachers.value.filter(teacher => teacher.userType.key == 'TEACHER');


    const academicYears = computed(() => {
      return [...new Set(semesters?.value?.map((semester) => semester.year))];
    });

    const academicYearSemesters = computed(() => {
      return semesters.value.filter(
        (semester) => semester.year == props.modelValue?.academicYear
      );
    });

    const onInputChange = () => {
      context.emit("update:modelValue", props.modelValue);
    };

    const onAcademicYearChange = () => {
      if (academicYearSemesters.value.length > 0) {
        props.modelValue.semester = academicYearSemesters.value[0];
      }

      context.emit("update:modelValue", props.modelValue);
    };

    watch(props, () => {
      context.emit("update:modelValue", props.modelValue);
    });

    return {
      rooms,
      teachers,
      semesters,
      degrees,
      courses,

      academicYears,
      academicYearSemesters,

      onInputChange,
      onAcademicYearChange,
    };
  },
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>
