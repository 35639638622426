import { ref, onMounted, onUnmounted, computed } from "vue";
import axios from "axios";

export function useDataService() {
  onMounted(() => {
    fetchRooms();
    fetchTeachers();
    fetchSemesters();
    fetchDegrees();
    fetchCourses();
  });

  let rooms = ref([]);
  let teachers = ref([]);
  let semesters = ref([]);
  let degrees = ref([]);
  let courses = ref([]);

  const fetchRooms = async () => {
    try {
      const response = await axios.get(`room`);

      rooms.value = response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(`user`);

      teachers.value = response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSemesters = async () => {
    try {
      const response = await axios.get(`semester`);

      semesters.value = response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDegrees = async () => {
    try {
      const response = await axios.get(`degree`);

      degrees.value = response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCourses = async () => {
    try {
      const response = await axios.get(`course`);

      courses.value = response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    rooms,
    teachers,
    semesters,
    degrees,
    courses,
  };
}
