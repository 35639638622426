<template>
    <vue-final-modal name="timeSlotModal"
        v-model="show"
        @close="onClose"
        @click-outside="onClose"
        classes="modal-container"
        content-class="modal-content"
        :min-width="200">
        <div class="w-90">


            <p class="mb-2 font-medium text-center">แทรกวิชาเรียนใหม่</p>
            <router-link v-for="time in timePeriods"
                :to="{
            name: 'courseMatching',
            query: {
                startTime: time,
                weekDay: weekDay,
                studentYear: studentYear,
                roomId: roomId,
            },
        }"
                data-cy="course-matching-links">
                <p class="mr-2 mt-1 hover:underline">{{ time }}</p>
            </router-link>

        </div>
    </vue-final-modal>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import { ref } from "vue";

export default {
    components: {
        BaseButton,
    },
    emits: ["submit", "close"],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        timePeriods: {
            type: Array,
            default: [],
        },
        weekDay: {
            type: String,
            required: false,
        },
        studentYear: {
            type: String,
            required: false,
        },
        roomId: {
            type: Number,
            required: false,
        },
    },
    setup(props, context) {

        const onClose = (e) => {
            context.emit("close")
        }

        const submit = (colorOption) => {
            context.emit('submit', { color: colorOption.border });
        }


        return { onClose, submit };
    },
};
</script>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #fff;
}

.modal__title {
    font-size: 1.5rem;
    font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>