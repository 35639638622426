<template>
  <div
    @click="navigateCourseMatchingFree"
    class="group flex h-full w-full items-center justify-center"
    :class="[canAccessCourseMatching ? 'cursor-pointer' : '']"
  >
    <IconAdd
      v-if="canAccessCourseMatching"
      class="cursor-pointer text-white group-hover:text-primary"
    />
  </div>
</template>

<script>
import IconAdd from "@/assets/icon/add.svg";
import { useRouter, useRoute } from "vue-router";

export default {
  props: {
    canAccessCourseMatching: {
      type: Boolean,
      default: false,
    },
    weekDay: {
      type: String,
      required: false,
    },
    studentYear: {
      type: String,
      required: false,
    },
    degreeId: {
      type: Number,
      required: false,
    },
    semesterId: {
      type: Number,
      required: false,
    },
    roomId: {
      type: Number,
      required: false,
    },
    teacherId: {
      type: Number,
      required: false,
    },
    startTime: {
      type: String,
      required: false,
    },
  },
  components: {
    IconAdd,
  },
  setup(props, context) {
    const router = useRouter();

    const navigateCourseMatchingFree = () => {
      if (!props.canAccessCourseMatching) {
        return;
      }

      router.push({
        name: "courseMatching",
        query: {
          weekDay: props.weekDay,
          studentYear: props.studentYear,
          degreeId: props.degreeId,
          semesterId: props.semesterId,
          startTime: props.startTime,
          roomId: props.roomId,
          teacherId: props.teacherId,
        },
      });
    };

    return {
      navigateCourseMatchingFree,
    };
  },
};
</script>
