<template>
  <pre style="font-size: 10px">
      <!-- {{ v$ }} -->
  </pre>
  <tr>
    <td class="py-4"
      style="width: 50px">
      <input data-cy="select-all-checkbox"
        type="checkbox"
        true-value="true"
        false-value="false" />
    </td>
    <td class="w-24 px-2">
      <input @input="onChange"
        data-cy="section-number"
        v-model="modelValue.number"
        type="text"
        class="input-text" />
    </td>
    <td v-if="![
          'INTERN',
          'IS',
          'COOP',
          'B_THESIS',
          'M_THESIS',
          'PHD_THESIS',
        ].includes(specialType)
          "
      class="w-48 px-2">
      <v-select @option:selected="onChange"
        data-cy="section-week-days"
        v-model="modelValue.weekDay"
        multiple
        :options="['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']">
      </v-select>
    </td>
    <td v-if="![
          'INTERN',
          'IS',
          'COOP',
          'B_THESIS',
          'M_THESIS',
          'PHD_THESIS',
        ].includes(specialType)
          "
      class="w-72 items-center px-2">
      <div class="flex">
        <v-select @option:selected="onChange"
          data-cy="section-start-time"
          v-model="modelValue.startTime"
          class="w-full"
          :options="times">
        </v-select>
        <span class="px-2">-</span>
        <v-select @option:selected="onChange"
          data-cy="section-finish-time"
          v-model="modelValue.finishTime"
          class="w-full"
          :options="times">
        </v-select>
      </div>
    </td>

    <td v-if="![
          'INTERN',
          'IS',
          'COOP',
          'B_THESIS',
          'M_THESIS',
          'PHD_THESIS',
        ].includes(specialType)
          "
      class="w-64 px-2">
      <v-select @option:selected="onChange"
        data-cy="section-room"
        v-model="modelValue.rooms"
        multiple
        :options="rooms"
        label="name">
      </v-select>

      <div v-if="modelValue?.roomOverlapped?.isOverlapped"
        class="my-2 text-xs text-red-500">
        มีการใช้ห้องซ้ำกับวิชาอื่น
      </div>
    </td>

    <td class="  "></td>

    <td class="w-64 px-2">
      <v-select @option:selected="onChange"
        data-cy="section-teacher"
        v-model="modelValue.teacher"
        :options="teachers"
        :get-option-label="(teacher) => `${teacher.firstname} ${teacher.lastname}`
          ">
      </v-select>

      <div v-if="isMainTeacherOverlapped"
        class="my-2 text-xs text-red-500">
        มีการใช้อาจารย์หลักซ้ำ
      </div>
    </td>

    <td v-if="['IS', 'COOP', 'B_THESIS', 'M_THESIS', 'PHD_THESIS'].includes(
          specialType
        )
          "
      class="w-64 px-2">
      <v-select multiple
        @option:selected="onChange"
        data-cy="section-teachers"
        v-model="modelValue.coTeachers"
        :options="teachers"
        :get-option-label="(teacher) => `${teacher.firstname} ${teacher.lastname}`
          ">
      </v-select>
    </td>

    <td class="w-32 px-2">
      <input @input="onChange"
        data-cy="section-capacity"
        v-model="modelValue.capacity"
        type="text"
        class="input-text" />

      <p v-if="modelValue.room"
        class="my-2 text-xs text-gray-darker"
        data-cy="section-capacity-hint">
        ความจุห้อง {{ modelValue.room?.capacity }} คน
      </p>

      <p v-if="isRoomOverCapacity"
        class="my-2 text-xs text-red-500"
        data-cy="section-capacity-warning">
        จำนวนเกินความจุห้อง
      </p>
    </td>
    <td class="w-32 px-2">
      <input @input="onChange"
        data-cy="section-registered-number"
        v-model="modelValue.registeredNumber"
        type="text"
        class="input-text" />
    </td>
    <td class="flex">
      <base-button-icon data-cy="section-duplicate"
        @click="onDuplicate"
        class="hover:bg-gray-lighter">
        <IconDuplicate class="font-bold text-primary" />
      </base-button-icon>


      <base-button-icon v-if="specialType == 'LEC_LAB'"
        data-cy="section-duplicate-across"
        @click="onDuplicateAcross"
        class="ml-2  hover:bg-gray-lighter">

        +
      </base-button-icon>

      <base-button-icon data-cy="section-delete"
        @click="onDelete"
        class="ml-2 hover:bg-red-50">
        <IconDelete class="text-red-500" />
      </base-button-icon>
    </td>
  </tr>



  <div v-if="!['INTERN', 'IS', 'COOP', 'B_THESIS', 'M_THESIS', 'PHD_THESIS'].includes(
          specialType
        )
          "
    class="mt-4 flex"
    data-cy="section-degrees">
    <div class="ml-14 rounded-sm bg-gray px-4 py-4"
      style="width: 820px">
      <table>
        <tr>
          <th class="w-3/4">หลักสูตรที่สอน</th>
          <th class="w-1/4">ชั้นปี</th>
        </tr>

        <tr v-for="(degree, index) in modelValue?.degrees">
          <td style="height: 0">
            <v-select v-model="modelValue.degrees[index].degree"
              :data-cy="`section-degrees-row-${index}`"
              @option:selected="onChange"
              class="bg-white"
              :options="degrees"
              label="nameTh"></v-select>
          </td>

          <td style="height: 0">
            <v-select v-model="modelValue.degrees[index].studentYears"
              @option:selected="onChange"
              :data-cy="`section-degree-student-years-row-${index}`"
              class="bg-white"
              multiple
              label="name"
              :options="studentYears"></v-select>
          </td>
        </tr>
      </table>

      <button @click="addDegreeInSection(section)"
        class="ml-4 mt-4 flex items-center text-sm font-medium">
        <IconAdd data-cy="section-add-degrees-button"
          class="mr-2"></IconAdd>
        เพิ่มหลักสูตร
      </button>
    </div>

    <div class="px-12">
      <p class="mt-6 text-sm font-bold text-primary">ผู้สอนร่วม</p>

      <v-select class="mt-2 w-60"
        v-model="modelValue.coTeachers"
        data-cy="section-degree-teachers"
        @option:selected="onChange"
        multiple
        :options="teachers"
        :get-option-label="(teacher) => `${teacher.firstname} ${teacher.lastname}`
          "></v-select>

      <div v-if="isCoTeachersOverlapped"
        class="my-2 text-xs text-red-500">
        มีการใช้อาจารย์ร่วมซ้ำ
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, computed } from "vue";

import BaseButtonIcon from "@/components/BaseButtonIcon";
import IconAdd from "@/assets/icon/add.svg";
import IconDelete from "@/assets/icon/delete.svg";
import IconDuplicate from "@/assets/icon/duplicate.svg";
import IconDuplicatePlus from "@/assets/icon/duplicate-plus.svg";

import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  requiredIf,
  email,
  decimal,
} from "@vuelidate/validators";
import { react } from "@babel/types";

export default {
  components: {
    IconAdd,
    IconDelete,
    IconDuplicate,
    IconDuplicatePlus,
    BaseButtonIcon,
  },
  emits: ["update:modelValue", "delete", "duplicate", "duplicateAcross"],

  props: {
    modelValue: {
      type: Object,
    },
    type: {
      type: String,
    },
    specialType: {
      type: String,
      default: "LEC_LAB",
    },
    rooms: {
      type: Array,
      default: [],
    },
    teachers: {
      type: Array,
      default: [],
    },
    semesters: {
      type: Array,
      default: [],
    },
    degrees: {
      type: Array,
      default: [],
    },
    courses: {
      type: Array,
      default: [],
    },
  },
  setup(props, context) {
    // const section = ref({
    //   number: null,
    //   weekDay: [],
    //   startTime: null,
    //   finishTime: null,
    //   capacity: 0,
    //   registeredNumber: 0,
    //   room: null,
    //   degrees: [{}],
    //   teacher: null,
    //   coTeachers: [],
    // });

    const times = [
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
    ];

    const studentYears = [
      {
        key: 1,
        name: "ปี 1",
      },
      {
        key: 2,
        name: "ปี 2",
      },
      {
        key: 3,
        name: "ปี 3",
      },
      {
        key: 4,
        name: "ปี 4",
      },
      {
        key: 5,
        name: "ปี 5",
      },
      {
        key: 'GE',
        name: "GE",
      },
    ];

    const isRoomOverCapacity = computed(() => {
      return props.modelValue?.capacity > props.modelValue?.room?.capacity;
    });

    const onChange = (value) => {
      context.emit("update:modelValue", {
        ...props.modelValue,
        type: props.type,
      });
    };

    const onDelete = () => {
      context.emit("delete");
    };

    const onDuplicate = () => {
      context.emit("duplicate");
    };

    const onDuplicateAcross = () => {
      context.emit("duplicateAcross");
    };

    const addDegreeInSection = (section) => {
      props.modelValue.degrees.push({});
    };

    const isMainTeacherOverlapped = computed(() => {
      return props.modelValue.teacherOverlapped?.overlappedTeacherIds?.includes(
        props.modelValue.teacher.id
      );
    });

    const isCoTeachersOverlapped = computed(() => {
      const allTeacherIds = props.modelValue?.coTeachers?.map(
        (teacher) => teacher.id
      );

      return (
        props.modelValue?.teacherOverlapped?.overlappedTeacherIds?.filter(
          (teacherId) => allTeacherIds.includes(teacherId)
        ).length > 0
      );
    });

    const rules = {
      number: { required },
    };

    let vuelidateState = ref({ ...props.modelValue });

    watch(
      () => props.modelValue,
      (first, second) => {
        vuelidateState.value = { ...props.modelValue };
      }
    );

    const v$ = useVuelidate(rules, vuelidateState);

    return {
      studentYears,
      times,

      isRoomOverCapacity,

      onChange,
      onDelete,
      onDuplicate,
      onDuplicateAcross,

      addDegreeInSection,

      isMainTeacherOverlapped,
      isCoTeachersOverlapped,

      v$,
      vuelidateState,
    };
  },
};
</script>
