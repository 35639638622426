<template>
    <div class="text-center h-screen flex flex-col justify-center">
        <p class="mt-20 font-light text-sm">เข้าสู่ระบบ</p>
    </div>
</template>

<script setup>
import axios from 'axios';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useLoading } from "vue-loading-overlay";

const router = useRouter();
const route = useRoute();
const $loading = useLoading();

onMounted(() => {
    loginWithMagicLink();
})

const loginWithMagicLink = async () => {
    const loader = $loading.show({});

    try {
        const { url } = route.query;

        const response = await axios.post(url);

        const { data: { token, redirect_to } } = response.data;

        localStorage.setItem("token", token);

        loader.hide();

        router.replace({ path: redirect_to });
    } catch (error) {
        loader.hide();
    }
}
</script>