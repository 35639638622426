<template>
  <div class="flex flex-col justify-between px-4"
    style="
      box-shadow: 2px 0px 6px rgba(80, 86, 131, 0.16);
      transition: 0.5s;
      height: calc(100vh - 20px);
    "
    :style="{
      minWidth: `${isSidebarExpanded ? 242 : 88}px`,
    }">
    <div>
      <div class="flex justify-center"
        style="padding: 22px 0; margin-right: -1px">
        <!-- <ArchLogo v-show="isSidebarExpanded"
          @click="toggleSideBarMode"
          class="cursor-pointer" /> -->


        <img v-show="isSidebarExpanded"
          @click="toggleSideBarMode"
          style="width: 100px"
          class="cursor-pointer"
          :src="cmsSetting?.logo_url"
          alt="" />

        <img v-show="sideBarMode == 'collapsed'"
          @click="toggleSideBarMode"
          class="pointer-events-auto mx-auto cursor-pointer"
          style="width: 52px"
          :src="cmsSetting?.logo_url"
          alt="" />
      </div>

      <div class="w-full"
        style="background-color: #d3d9ef; height: 1px; margin-top: 8.5px"></div>

      <div style="margin-top: 50px">
        <router-link v-if="canAccessCourseMatching"
          :to="{ name: 'transactionalData' }"
          data-cy="transaction-menu">
          <side-menu-item :mode="sideBarMode"
            title="ฟอร์มนำเข้าข้อมูล">
            <IconForm />
          </side-menu-item>
        </router-link>

        <router-link v-if="canAccessClassSchedule"
          :to="{ name: 'classSchedule' }"
          data-cy="class-schedule-menu">
          <side-menu-item :mode="sideBarMode"
            title="ตารางสอน">
            <IconCalendar />
          </side-menu-item>
        </router-link>

        <router-link v-if="canAccessClassSchedule"
          :to="{ name: 'roomSchedule' }"
          data-cy="room-schedule-menu">
          <side-menu-item :mode="sideBarMode"
            title="ร่างตารางการใช้ห้อง">
            <IconBuilding />
          </side-menu-item>
        </router-link>

        <a :href="roomBookingUrl">
          <side-menu-item v-if="canAccessRoomBooking"
            title="
            ระบบจองห้อง">
            <IconBuilding />
          </side-menu-item>
        </a>

        <a :href="workloadUrl">
          <side-menu-item v-if="canAccessWorkload"
            :mode="sideBarMode"
            title="ภาระงานสอน"
            data-cy="workload-menu">
            <IconWorkload />
          </side-menu-item>
        </a>

        <a :href="overWorkloadUrl">
          <side-menu-item v-if="canAccessOverWorkload"
            :mode="sideBarMode"
            title="ภาระงานสอนเกิน"
            data-cy="workload-menu">
            <IconOverWorkload />
          </side-menu-item>
        </a>
      </div>
    </div>

    <div class="mb-4">
      <div v-show="isSidebarExpanded"
        class="mb-2 px-4 text-base text-primary">
        <p>{{ `${user.firstname} ${user.lastname}` }}</p>
        <!-- <p class="text-xs font-bold">{{ `${user.role.name}` }}</p> -->
      </div>

      <side-menu-item :mode="sideBarMode"
        @click="logout"
        title="ออกจากระบบ">
        <IconLogout class="text-primary" />
      </side-menu-item>
    </div>
  </div>
</template>

<script>
import ArchLogo from "@/assets/logo.svg";
import IconDb from "@/assets/icon/db.svg";
import IconChart from "@/assets/icon/chart.svg";
import IconForm from "@/assets/icon/form.svg";
import IconCalendar from "@/assets/icon/calendar.svg";
import IconBuilding from "@/assets/icon/building.svg";
import IconWorkload from "@/assets/icon/workload.svg";
import IconOverWorkload from "@/assets/icon/over-workload.svg";

import IconLogout from "@/assets/icon/logout.svg";

import SideMenuItem from "@/components/SideMenuItem.vue";
import TopMenuItem from "@/components/TopMenuItem.vue";
import TopSubMenuItem from "@/components/TopSubMenuItem.vue";

import TopHeader from "@/components/TopHeader.vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";

import axios from "axios";
import { computed } from "@vue/runtime-core";
import { useCan } from "@/composables/authentication";

import EnvProvider from "jvjr-docker-env";

export default {
  components: {
    ArchLogo,

    IconDb,
    IconChart,
    IconForm,
    IconCalendar,
    IconLogout,
    IconBuilding,
    IconWorkload,
    IconOverWorkload,

    SideMenuItem,
    TopMenuItem,
    TopSubMenuItem,
    TopHeader,

    useRoute,
  },
  events: ["change"],
  setup(props, context) {
    const store = useStore();
    const user = computed(() => store.state.authentication);
    const token = localStorage.getItem("token");

    const {
      canAccessMasterData,
      canAccessCourseMatching,
      canAccessClassSchedule,
      canAccessRoomSchedule,
      canAccessWorkload,
      canAccessOverWorkload,
      canAccessRoomBooking,
    } = useCan();


    const logoWithText = EnvProvider.value("LOGO_URL");
    const logoNoText = EnvProvider.value("LOGO_NO_TEXT_2_URL");

    const logout = async () => {
      try {
        const response = await axios.post("auth/logout");

        store.commit("updateAuthentication", null);

        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    };

    const sideBarMode = ref("expanded");

    const toggleSideBarMode = () => {
      sideBarMode.value =
        sideBarMode.value == "expanded" ? "collapsed" : "expanded";

      context.emit("change", sideBarMode.value);
    };

    const isSidebarExpanded = computed(() => {
      return sideBarMode.value == "expanded";
    });


    onMounted(() => {
      fetchRoomBookingUrl();
      fetchOverWorkloadUrl();
      fetchWorkloadUrl();
      fetchSetting();
      fetchAdmin();
    });

    const emsBaseUrl = `${EnvProvider.value("EMS_BASE_URL")}`;
    const emsExternalUrl = `${emsBaseUrl}login-external`;

    const adminBaseUrl = `${EnvProvider.value("ADMIN_BASE_URL")}`;
    console.log(adminBaseUrl);


    const roomBookingUrl = ref(null);
    const fetchRoomBookingUrl = async () => {
      try {
        const response = await axios.post("auth/magic-login-link", {
          redirect_path: "/room_booking",
          external_url: emsExternalUrl
        });
        const { data: { url, external_url } } = response.data;

        roomBookingUrl.value = `${external_url}?url=${url}`;
      } catch (error) {

      }
    }

    const workloadUrl = ref(null);
    const fetchWorkloadUrl = async () => {
      try {
        const response = await axios.post("auth/magic-login-link", {
          redirect_path: "/workload",
          external_url: emsExternalUrl
        });
        const { data: { url, external_url } } = response.data;

        workloadUrl.value = `${external_url}?url=${url}`;
      } catch (error) {

      }
    }

    const overWorkloadUrl = ref(null);
    const fetchOverWorkloadUrl = async () => {
      try {
        const response = await axios.post("auth/magic-login-link", {
          redirect_path: "/over_workload",
          external_url: emsExternalUrl
        });
        const { data: { url, external_url } } = response.data;

        overWorkloadUrl.value = `${external_url}?url=${url}`;
      } catch (error) {

      }
    }

    const adminUrl = ref(null);
    const fetchAdmin = async () => {
      try {
        const response = await axios.post("auth/magic-login-link-web", {
          redirect_path: "/admin",
          external_url: `${adminBaseUrl}auth/login-with-magic-link-web`
        });

        const { data: { url, external_url } } = response.data;

        adminUrl.value = `${adminBaseUrl}${url}`;
      } catch (error) {

      }
    }

    const cmsSetting = ref(null);
    const fetchSetting = async () => {
      const response = await axios.get("cms-setting");
      const { data } = response.data;
      cmsSetting.value = data;
    }


    return {
      user,
      token,
      logoWithText,
      logoNoText,
      cmsSetting,

      adminUrl,
      roomBookingUrl,
      workloadUrl,
      overWorkloadUrl,

      sideBarMode,
      toggleSideBarMode,
      isSidebarExpanded,

      canAccessMasterData,
      canAccessCourseMatching,
      canAccessClassSchedule,
      canAccessRoomSchedule,
      canAccessWorkload,
      canAccessOverWorkload,
      canAccessRoomBooking,

      logout,
    };
  },
};
</script>
