<template>
  <AddDataModal @submit="onAddingSelectNumberOfRows" />
  <ImageModal />

  <ImageUploaderModal @uploaded="onImageUploaded" />

  <ConfirmationModal data-cy="delete-single-modal"
    title="ยืนยันการลบข้อมูล"
    name="deleteRowConfirmModal"
    confirmText="ยืนยัน"
    cancelText="ยกเลิก"
    @confirm="onDeleteRowConfirm"
    @cancel="onDeleteRowCancel" />

  <ConfirmationModal data-cy="delete-multiple-modal"
    :title="`ยืนยันการลบข้อมูล ${selectedData.length} รายการ`"
    name="deleteMultipleRowConfirmModal"
    confirmText="ยืนยัน"
    cancelText="ยกเลิก"
    @confirm="onDeleteMultipleRowConfirm"
    @cancel="" />

  <ConfirmationModal data-cy="discard-modal"
    :title="`ต้องการยกเลิกการเปลี่ยนแปลง`"
    name="discardEditConfirmModal"
    confirmText="ยืนยัน"
    cancelText="ยกเลิก"
    @confirm="onEditDiscardConfirm"
    @cancel="" />

  <Fab v-if="editing">
    <FabItem data-cy="add-multiple-button"
      @click="() => $vfm.show('addDataModal')"
      title="เพิ่มหลายรายการ">
      <IconDocumentMultiple class="mr-2 w-6" />
    </FabItem>

    <FabItem data-cy="add-single-button"
      @click="() => onAddingSelectNumberOfRows()"
      title="เพิ่ม 1 รายการ">
      <IconDocument class="mr-2 w-6" />
    </FabItem>
  </Fab>

  <div class="mb-1 flex h-5 items-center justify-end text-sm text-gray-darker">
    <ClipLoader data-cy="save-loading"
      v-show="loading"
      size="15px"
      color="gray"
      class="mr-2" />

    <span v-show="loading"
      class="mb-2">กำลังบันทึกข้อมูล</span>
  </div>

  <div class="filter-bar flex mb-2">
    <div>
      <div v-for="select in filter?.selects">
        <p>{{ select.inputLabel }}</p>
        <v-select @update:modelValue="
          (value) => onFilterChanged(value, select.filterFunction)
        "
          :options="select.options"
          :label="select.label"
          :style="{ width: select.width }">
        </v-select>
      </div>
    </div>

    <div>
      <div v-for="search in filter?.searches">
        <p>{{search.inputLabel}}</p>
        <input @input="
          (value) =>
            onFilterChanged(value?.target?.value, search.filterFunction)
        "
          class="input-text"
          type="text" />
      </div>
    </div>
  </div>

  <div class="mb-4 flex h-10 items-center justify-between">
    <div class="flex items-center">
      <p data-cy="total-number"
        class="text-base text-primary">
        {{ data.length }} รายการ
      </p>
      <p data-cy="selected-number"
        class="ml-2 text-xs text-gray-darker">
        (เลือก {{ selectedData.length }} รายการ)
      </p>
    </div>

    <div class="flex">
      <div v-if="editing"
        class="flex">
        <button data-cy="selected-delete-button"
          v-if="selectedData.length"
          @click="onDeleteMultipleRow"
          class="text-sm font-bold text-red-500">
          ลบรายการที่ถูกเลือก
        </button>

        <BaseButton data-cy="selected-delete-cancel"
          type="outline"
          @click="onEditCancel"
          title="ยกเลิก"></BaseButton>

        <BaseButton data-cy="save-button"
          @click="onSaveEdit"
          type="solid"
          title="บันทึก">
          <IconSave class="text-white" />
        </BaseButton>
      </div>

      <BaseButton v-if="!editing"
        data-cy="enable-edit-button"
        type="solid"
        @click="enterEditingMode"
        title="แก้ไขข้อมูล">
        <IconEdit class="text-white" />
      </BaseButton>
    </div>
  </div>

  <table class="table-header">
    <tr class="h-10 bg-gray text-xs text-primary">
      <th style="width: 50px">
        <input data-cy="select-all-checkbox"
          v-if="editing"
          @change="onSelectAllChange"
          type="checkbox"
          true-value="true"
          false-value="false" />
      </th>
      <th style="width: 75px">ที่</th>

      <th v-for="column in columns"
        :style="{ width: column.width }">
        <span class="px-2">{{ column.title }}</span>
      </th>
      <th></th>
    </tr>
  </table>

  <div class="table-body"
    :class="[editing ? 'editable' : '']">
    <table ref="$dataTable"
      class="h-16 w-full pb-40">
      <!-- Display -->
      <TransitionGroup name="list">
        <tr v-for="(row, index) in data"
          :data-cy="`data-row-${index}`"
          :key="row.id">
          <td style="width: 50px">
            <input v-if="editing"
              :data-cy="`select-row-${index}-checkbox`"
              type="checkbox"
              v-model="row.selected" />
          </td>
          <td style="width: 75px">
            {{ index + 1 }}
          </td>

          <td v-for="column in columns"
            class="bg py-4"
            :style="{ width: column.width }">
            <div v-for="key in column.keys">
              <slot v-if="!row.editing"
                :name="`${key}${'-display'}`"
                :value="row">
                <p :data-cy="`row-${index}-${key}`"
                  style="padding-left: 9px">
                  <IconImage v-if="column.input == 'image' && row[key]"
                    @click="showImageModal(row[key])" />

                  <template v-else>
                    <span v-if="column?.hasOwnProperty('labelKey')">
                      {{ row?.[key]?.[column?.labelKey] }}
                    </span>

                    <span v-else-if="row?.[key]?.hasOwnProperty('name')">{{
                    row[key]?.name
                    }}</span>

                    <span v-else>{{ row?.[key] }}</span>
                  </template>
                </p>
              </slot>

              <slot v-if="row.editing"
                :name="`${key}${'-input'}`"
                :value="row">
                <input v-if="column.input == 'text'"
                  :data-cy="`row-${index}-${key}-edit-input`"
                  class="input-text"
                  type="text"
                  v-model="row[key]" />

                <input v-if="column.input == 'date'"
                  :data-cy="`row-${index}-${key}-edit-date`"
                  class="input-text"
                  type="date"
                  v-model="row[key]" />

                <v-select v-if="column.input == 'selectText'"
                  :data-cy="`row-${index}-${key}-add-select-text`"
                  v-model="row[key]"
                  :label="
                    column.hasOwnProperty('labelKey') ? column.labelKey : 'name'
                  "
                  :options="column.options" />

                <v-select v-if="column.input == 'select'"
                  :data-cy="`row-${index}-${key}-edit-select`"
                  v-model="row[key]"
                  :label="
                    column.hasOwnProperty('labelKey') ? column.labelKey : 'name'
                  "
                  :options="column.options" />

                <base-button-icon v-if="column.input == 'image'"
                  @click="showImageUploaderModal(row, key)"
                  :data-cy="`row-${index}-upload`"
                  class="hover:bg-gray-lighter">
                  <IconUpload class="font-bold text-primary" />
                </base-button-icon>
              </slot>
            </div>
          </td>

          <td>
            <DataItemActionBar v-show="editing"
              :editing="row.editing"
              :index="index"
              @edit="onEditRow(row, index)"
              @duplicate="onAddingDuplicate(row, index)"
              @cancel-edit="onCancelEditRow(row, index)"
              @delete="onDeleteRow(row, index)" />
          </td>
        </tr>

        <!-- Adding -->
        <tr v-for="(row, index) in dataAdding"
          :key="index"
          :data-cy="`data-add-row-${index}`">
          <td style="width: 50px">
            <input type="checkbox"
              v-model="row.selected" />
          </td>
          <td style="width: 75px">
            {{ index + data.length + 1 }}
          </td>

          <td v-for="column in columns"
            class=""
            :style="{ width: column.width }">
            <div v-for="(key, keyIndex) in column.keys"
              class="mb-2">
              <input v-if="column.input == 'text'"
                :data-cy="`row-${index}-${key}-add-input`"
                class="input-text"
                type="text"
                :placeholder="
                  column.placeholders
                    ? column.placeholders[keyIndex]
                    : column.title
                "
                v-model="row[key]" />

              <input v-if="column.input == 'date'"
                :data-cy="`row-${index}-${key}-add-date`"
                class="input-text"
                type="date"
                :placeholder="
                  column.placeholders
                    ? column.placeholders[keyIndex]
                    : column.title
                "
                v-model="row[key]" />

              <v-select v-if="column.input == 'selectText'"
                :data-cy="`row-${index}-${key}-add-select-text`"
                v-model="row[key]"
                :label="
                  column.hasOwnProperty('labelKey') ? column.labelKey : 'name'
                "
                :options="column.options" />

              <v-select v-if="column.input == 'select'"
                :data-cy="`row-${index}-${key}-add-select`"
                v-model="row[key]"
                :label="
                  column.hasOwnProperty('labelKey') ? column.labelKey : 'name'
                "
                :options="column.options" />

              <base-button-icon v-if="column.input == 'image'"
                @click="showImageUploaderModal(row, key)"
                :data-cy="`row-${index}-upload`"
                class="hover:bg-gray-lighter">
                <IconUpload class="font-bold text-primary" />
              </base-button-icon>
            </div>
          </td>

          <td>
            <DataItemActionBar :adding-mode="true"
              @duplicate="onAddingDuplicate(row, index)"
              @delete="onAddingDelete(row, index)" />
          </td>
        </tr>
      </TransitionGroup>
    </table>
  </div>
</template>

<script>
import IconEdit from "@/assets/icon/edit.svg";
import IconDelete from "@/assets/icon/delete.svg";
import IconSave from "@/assets/icon/save.svg";
import IconDocument from "@/assets/icon/document.svg";
import IconDocumentMultiple from "@/assets/icon/document-multiple.svg";
import IconImage from "@/assets/icon/image.svg";
import IconUpload from "@/assets/icon/upload.svg";

import BaseButtonIcon from "@/components/BaseButtonIcon";
import BaseButton from "@/components/BaseButton";
import DataItemActionBar from "@/components/DataItemActionBar";

import AddDataModal from "@/components/modal/AddDataModal";
import ImageModal from "@/components/modal/ImageModal";

import ConfirmationModal from "@/components/modal/ConfirmationModal";
import ImageUploaderModal from "@/components/modal/ImageUploaderModal.vue";

import Fab from "@/components/fab/Fab";
import FabItem from "@/components/fab/FabItem";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import { reactive, ref } from "@vue/reactivity";
import { computed, toRaw, unref, onBeforeUnmount } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";

import { $vfm } from "vue-final-modal";

import { useToast } from "vue-toast-notification";

import {
  columnsToObject,
  generateEmptyObjects,
  optionObjectToId,
  getChangedData,
} from "@/composables/data-table";

import axios from "axios";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      default: [],
    },
    filter: {
      type: Object,
    },
  },
  emits: ["save"],
  components: {
    IconEdit,
    IconDelete,
    IconSave,
    IconDocument,
    IconDocumentMultiple,
    IconImage,
    IconUpload,

    BaseButton,
    BaseButtonIcon,
    DataItemActionBar,
    AddDataModal,
    ImageModal,
    ConfirmationModal,
    Fab,
    FabItem,
    ClipLoader,
    ImageUploaderModal,
  },

  setup(props, context) {
    let dataSource = ref([]);
    let data = ref([]);
    let dataFilterResults = ref([]);

    let loading = ref(false);

    let $dataTable = ref(null);

    const fetchDataSource = async () => {
      try {
        const response = await axios.get(`${props.name}`);

        dataSource.value = response.data.data;

        data.value = dataSource.value.map((data) => ({
          ...data,
          editing: false,
          edited: false,
          selected: false,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataSource();

    const onSelectAllChange = (event) => {
      const { checked } = event.target;

      data.value = data.value.map((d) => ({
        ...d,
        selected: checked,
      }));
    };

    const selectedData = computed(() => {
      const selected = data.value.filter((data) => data.selected);

      return selected;
    });

    const editing = ref(false);

    const enterEditingMode = () => {
      editing.value = true;
    };

    const exitEditingMode = () => {
      editing.value = false;
    };

    const onEditRow = (row, index) => {
      data.value[index] = { ...row, editing: true, edited: true };
    };

    const onCancelEditRow = (row, index) => {
      data.value[index] = { ...row, editing: false };
    };

    const onDeleteMultipleRow = () => {
      console.log(selectedData);

      $vfm.show("deleteMultipleRowConfirmModal");
    };

    const onDeleteMultipleRowConfirm = async () => {
      const ids = selectedData.value.map((row) => row.id);

      try {
        await axios.delete(`${props.name}`, { data: { ids } });

        dataSource.value = dataSource.value.filter((d) => !ids.includes(d.id));
        data.value = data.value.filter((d) => !ids.includes(d.id));

        useToast().success("ลบข้อมูลแล้ว");
      } catch (error) {
        useToast().error("ลบข้อมูลไม่สำเร็จ");

        console.log(error);
      }
    };

    let deletingRow = ref(null);

    const resetDeltingRow = () => {
      deletingRow.value = null;
    };

    const onDeleteRow = (row, index) => {
      deletingRow.value = row;

      $vfm.show("deleteRowConfirmModal");
    };

    const onDeleteRowConfirm = async () => {
      try {
        await axios.delete(`${props.name}/${deletingRow.value.id}`);

        dataSource.value = dataSource.value.filter(
          (d) => d.id != deletingRow.value.id
        );
        data.value = data.value.filter((d) => d.id != deletingRow.value.id);

        resetDeltingRow();
        useToast().success("ลบข้อมูลแล้ว");
      } catch (error) {
        resetDeltingRow();
        useToast().error("ลบข้อมูลไม่สำเร็จ");
      }
    };

    const onDeleteRowCancel = () => {
      resetDeltingRow();
    };

    let dataAdding = ref([]);

    const onAddingSelectNumberOfRows = (rows = 1) => {
      dataAdding.value.push(...generateEmptyObjects(props.columns, rows));

      scrollToElement({ behavior: "smooth" });
    };

    const onAddingDelete = (row, index) => {
      dataAdding.value.splice(index, 1);
    };

    const onAddingDuplicate = (row, index) => {
      dataAdding.value.push({ ...row });

      scrollToElement({ behavior: "smooth" });
    };

    const adding = computed(() => {
      return dataAdding.value.length > 0;
    });

    const dataChanged = computed(() => {
      let changed = getChangedData(data.value, dataSource.value);

      return optionObjectToId(changed);
    });

    const onSaveEdit = async () => {
      const dataNew = optionObjectToId(dataAdding.value);

      if (dataChanged.value.length) {
        loading.value = true;

        try {
          const response = await axios.put(`${props.name}`, dataChanged.value);
          const responseData = response.data.data;

          const updatedIds = responseData.map((d) => d.id);

          updatedIds.forEach((id) => {
            const dataSourceRowIndex = dataSource.value.findIndex(
              (d) => d.id == id
            );
            const updatedRow = responseData.find((d) => d.id == id);

            dataSource.value[dataSourceRowIndex] = updatedRow;
          });

          cancelEditAllRows();

          loading.value = false;

          useToast().success("บันทึกแล้ว");
        } catch (error) {
          loading.value = false;
          useToast().error("บันทึกไม่สำเร็จ");
        }
      } else {
        cancelEditAllRows();
      }

      if (dataNew.length) {
        loading.value = true;

        try {
          const response = await axios.post(`${props.name}`, dataNew);
          const responseData = response.data.data;

          dataSource.value.push(...responseData);

          const dataWithMeta = responseData.map((d) => ({
            ...d,
            editing: false,
            edited: false,
            selected: false,
          }));

          data.value.push(...dataWithMeta);

          dataAdding.value = [];

          loading.value = false;
          useToast().success("บันทึกแล้ว");
        } catch (error) {
          loading.value = false;
          useToast().error("บันทึกไม่สำเร็จ");
        }
      }
    };

    const onEditCancel = () => {
      if (unsaved.value) {
        $vfm.show("discardEditConfirmModal");
      } else {
        cancelEditAllRows();
      }
    };

    const cancelEditAllRows = () => {
      editing.value = false;

      data.value = dataSource.value.map((data) => ({
        ...data,
        editing: false,
        edited: false,
        selected: false,
      }));
    };

    const onEditDiscardConfirm = () => {
      editing.value = false;

      data.value = dataSource.value.map((data) => ({
        ...data,
        editing: false,
        edited: false,
        selected: false,
      }));

      dataAdding.value = [];
    };

    const onFileInputChange = async (e, row, key) => {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      let formData = new FormData();
      formData.append("image", files[0]);

      try {
        const response = await axios.post("file/upload", formData, {
          headers: { "content-type": "multipart/form-data" },
        });

        const { data } = response.data;

        row[key] = data.url;
      } catch (error) {
        console.log(error);
      }
    };

    const showImageModal = (imageUrl) => {
      $vfm.show("imageModal", { imageUrl });
    };

    const showImageUploaderModal = (row, key) => {
      $vfm.show("imageUploaderModal", { row, key });
    };

    const onImageUploaderModalClosed = () => { };

    const onImageUploaded = ({ url, row, key }) => {
      console.log(url, row, key);
      row[key] = url;
    };

    const unsaved = computed(() => {
      let changedData = getChangedData(data.value, dataSource.value);

      return changedData.length > 0 || dataAdding.value.length > 0;
    });

    const onFilterChanged = (filterValue, filterFunction) => {

      if (!filterValue) {
        data.value = dataSource.value.map((data) => ({
          ...data,
          editing: false,
          edited: false,
          selected: false,
        }));

        return;
      }

      data.value = dataSource.value.filter((value) => {
        return filterFunction(value, filterValue);
      });
    };

    onBeforeRouteUpdate((to, from) => {
      if (unsaved.value) {
        const answer = window.confirm(
          "Do you really want to leave? you have unsaved changes!"
        );
        // cancel the navigation and stay on the same page
        if (!answer) return false;
      }
    });

    const scrollToElement = (options) => {
      const el = $dataTable.value.lastElementChild;

      setTimeout(() => {
        if (el) {
          el.scrollIntoView(options);
        }
      }, 200);
    };

    return {
      props,

      loading,

      dataSource,
      data,

      dataChanged,

      selectedData,
      onSelectAllChange,

      editing,
      enterEditingMode,
      exitEditingMode,

      onEditRow,

      deletingRow,
      onDeleteRow,
      onDeleteRowConfirm,
      onDeleteRowCancel,

      onDeleteMultipleRow,
      onDeleteMultipleRowConfirm,

      onCancelEditRow,
      onSaveEdit,

      adding,
      onAddingSelectNumberOfRows,
      onAddingDelete,
      onAddingDuplicate,

      dataAdding,

      onEditCancel,
      onEditDiscardConfirm,

      onFileInputChange,

      showImageModal,
      showImageUploaderModal,
      onImageUploaderModalClosed,
      onImageUploaded,

      unsaved,

      onFilterChanged,

      $dataTable,
      scrollToElement,
    };
  },
};
</script>

<style scoped>
input {
  @apply mt-2;
}

td {
  @apply py-4;
}

/* * {
  overflow-x: hidden;
} */

/* ::-webkit-scrollbar {
  width: 0;
  background: transparent; 
} */

.filter-bar> :not(:first-child) {
  @apply ml-4;
}

.list-leave-active {
  display: absolute;
  transition: all 0.5s ease;
}

.list-leave-from {
  opacity: 100;
}

.list-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

.list-enter-active {
  display: absolute;
  transition: all 0.5s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateX(20px);
}

.list-enter-to {
  opacity: 100;
}
</style>
