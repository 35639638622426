<template>
  <div class="mt-8 flex items-center">
    <p class="w-32 text-lg text-gray-darker">{{ title }}</p>
    <div class="horizontal-line"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
