<template>
  <router-view></router-view>
</template>

<script>
import RoomSchedule from "./components/ScheduleTable.vue";

export default {
  name: "App",
  components: {
    RoomSchedule,
  },
};
</script>

<style>


</style>
