import { createApp } from "vue";
import App from "./App.vue";
import "./main.css";

const app = createApp(App);

// Vue Select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
app.component("v-select", vSelect);

// Router
import router from "../router";
app.use(router);

// Axios
import axios from "../axios";

// Vue Modal
import vfmPlugin from "vue-final-modal";
app.use(vfmPlugin);

//Toast
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
app.use(VueToast);

//Vuex
import store from "../vuex/store.js";
app.use(store);

//Loading
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
app.use(VueLoading);

//DropZone
import DropZone from "dropzone-vue";
app.use(DropZone);

app.mount("#app");
